/* eslint-disable no-shadow */
// Yoinked from https://github.com/tessenate/polyhedra-viewer/blob/e96d6ebe0b78c35ec7613d35a48914ada9967e46/src/components/ConditionTransitionMotion.js
/* Example
 <ConditionTransitionMotion
   condition={this.state.show}
   willEnter={() => ({ x: startX, opacity: 0 })}
   willLeave={() => ({ x: spring(startX), opacity: spring(0) })}
   style={{ x: spring(0), opacity: spring(1)}}>
 { ({x, opacity}) =>
 <div style={{ transform: `translateX(${x}px)`, opacity, }}>
 ...
 </div>
 }
 </ConditionTransitionMotion>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TransitionMotion, Style } from 'react-motion';

interface ConditionTransitionMotionProps {
  condition: boolean;
  willEnter?: () => Style;
  willLeave?: () => Style;
  style?: any;
  children: any;
}

const ConditionTransitionMotion = ({
  condition,
  style,
  children,
  ...props
}: ConditionTransitionMotionProps) => {
  const styles = condition ? [{ key: 'single', style }] : [];
  return (
    // I give up on making the spread props work here...
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <TransitionMotion styles={styles} {...props}>
      {(interpolatedStyles) => (
        <div>
          {/* eslint @typescript-eslint/no-shadow: "warn" */}
          {interpolatedStyles.map(({ key, style }) => (
            <div key={key}>{children(style)}</div>
          ))}
        </div>
      )}
    </TransitionMotion>
  );
};

ConditionTransitionMotion.propTypes = {
  condition: PropTypes.bool,
  style: PropTypes.any,
  children: PropTypes.any
};

export default ConditionTransitionMotion;
