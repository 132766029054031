import _ from 'lodash';

import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { select as soqlSelect, f, composeQuery, ref, and } from 'common/soql_builder';
import { SoqlDataProviderConfig } from './SoqlDataProvider';
import { SoQLType } from 'common/types/soql';

interface Extent {
  northeast: number[];
  southwest: number[];
}

class GeospaceDataProvider extends SoqlDataProvider {
  constructor(config: SoqlDataProviderConfig, useCache = false) {
    super(config);

    if (useCache) {
      const cached = this.cachedInstance('GeospaceDataProvider');
      if (cached) {
        return cached as GeospaceDataProvider;
      }
    }
  }

  /**
   * Get the geographic bounds for a dataset, used primarily for centering a new map
   * on the rows in the data source.
   */
  getFeatureExtent(
    columnName: string,
    columnDataType?: SoQLType,
    ignoreInvalidLatLng?: boolean,
    whereClauseComponent?: string
  ) {
    const EXTENT_NAME = 'extent_' + columnName;
    const query = (fieldName: string) => {
      // The `extent` soql function does not take "location" type columns, so
      // we cast them to "point"
      const castColumn =
        columnDataType === SoQLType.SoQLLocationT
          ? f.cast(SoQLType.SoQLPointT)(ref(fieldName))
          : ref(fieldName);
      const queryWithoutWhere = composeQuery({
        selects: [soqlSelect(f.extent(castColumn), EXTENT_NAME)],
        where: ignoreInvalidLatLng ? f.withinBox(ref(fieldName), -90, -180, 90, 180) : undefined
      });

      if (whereClauseComponent) {
        if (ignoreInvalidLatLng) {
          return queryWithoutWhere + ' AND ' + whereClauseComponent;
        } else {
          return queryWithoutWhere + ' WHERE ' + whereClauseComponent;
        }
      }

      return queryWithoutWhere;
    };

    return super.invokeSoqlQuery(query, [columnName]).then((response) => {
      const coordinates = _.get(response, `[0].${EXTENT_NAME}.coordinates[0][0]`);

      if (!_.isUndefined(coordinates)) {
        return {
          southwest: [coordinates[0][1] as number, coordinates[0][0] as number],
          northeast: [coordinates[2][1] as number, coordinates[2][0] as number]
        };
      }
    });
  }

  /**
   * @deprecated This is only used for legacy Region maps (also known as old new region maps).
   * To create this type of map, set enable_new_maps to false.
   */
  getShapefile(extent?: Extent) {
    const extentValidationErrorMessage =
      'Argument `extent` must be an object ' +
      'with two keys: `southwest` and `northeast`; the value assigned to ' +
      'each key must be an array of two numbers in the following format: `[' +
      'latitude, longitude]`.';

    // Do not use a looser test for falsiness because if an invalid extent is
    // provided in any form we want to kick an error up to help with debugging.
    if (!_.isUndefined(extent) && !this.extentIsValid(extent)) {
      return new Promise(function (resolve, reject) {
        return reject({
          status: -1,
          message: extentValidationErrorMessage,
          soqlError: null
        });
      });
    }

    const query = (_extent?: Extent) => {
      return composeQuery({
        // This ancient query really wants to select *
        // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
        selects: [soqlSelect('*')],
        where: _extent
          ? f.intersects(ref('the_geom'), `MULTIPOLYGON(((${this.mapExtentToMultipolygon(_extent)})))`)
          : undefined,
        limit: 5000
      });
    };

    return super.invokeSoqlQuery(query, [extent], undefined, 'geojson');
  }

  private extentIsValid(extent: Extent) {
    return (
      // Validate that it is an object with northeast and
      // southwest properties.
      _.isObject(extent) &&
      // Next validate the northeast property.
      _.isArray(extent.northeast) &&
      extent.northeast.length === 2 &&
      _.every(extent.northeast, _.isNumber) &&
      // Then validate the southwest property.
      _.isArray(extent.southwest) &&
      extent.southwest.length === 2 &&
      _.every(extent.southwest, _.isNumber)
    );
  }

  /**
   * Multipolygon queries expect a polygon in clockwise order, starting from
   * the bottom left. Polygons are closed, meaning that the start and end
   * points must be identical.
   *
   * Example:
   *
   * 2----3
   * |    |
   * 1,5--4
   *
   * Where each pair is: longitude latitude
   */
  private mapExtentToMultipolygon(extent: Extent) {
    const lowerLeft = `${extent.southwest[1]} ${extent.southwest[0]}`;
    const upperLeft = `${extent.southwest[1]} ${extent.northeast[0]}`;
    const upperRight = `${extent.northeast[1]} ${extent.northeast[0]}`;
    const lowerRight = `${extent.northeast[1]} ${extent.southwest[0]}`;

    return `${lowerLeft},${upperLeft},${upperRight},${lowerRight},${lowerLeft}`;
  }
}

export default GeospaceDataProvider;
