import _ from 'lodash';
import I18n from 'common/i18n';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './St... Remove this comment to see the full error message
import * as StringHelpers from './StringHelpers';
import { DATE_LABEL_TRANSLATIONS } from '../views/SvgConstants';
import d3 from 'd3';

/**
 * Layout Helpers
 */

/**
 * Maps your text data to positioned text rows. Text will be word wrapped
 * into rows and each of the row elements will have relative positions to
 * the column.
 *
 * Example usage:
 *
 * ```javascript
 * const layout = textBasedColumnLayout({
 *   fontFamily: 'Helvetica',
 *   fontSize: 24
 * });
 *
 * // margin from the container
 * layout.setMargin(top, right, bottom, left);
 *
 * // Set item's inner padding
 * layout.setItemPadding(top, right, bottom, left);
 *
 * // gap between columns (horizontal, vertical)
 * layout.setColumnGap(horizontal, vertical);
 *
 * // Set minimum allowed text width, this affects max number of
 * // column calculations
 * layout.setMinimumTextWidth(width);
 *
 * // Set maximum desired columns
 * layout.setMaximumColumns(maxColumns);
 *
 * // do calculations
 * const columnPositionedData = layout(containerWidth, containerHeight, myTextArray);
 *
 * d3selector.
 *   selectAll('g').
 *   data(columnPositionedData).
 *     enter().
 *     append('g').
 *       attr('transform', (d) => `translate(${d.x},${d.y})`).
 *       selectAll('text').
 *       data((d) => d.wrappedText).
 *         enter().
 *         append('text').
 *           text((d) => d.text).
 *           attr('x', (d) => d.x).
 *           attr('y', (d) => d.y);
 *
 * ```
 */
export function textBasedColumnLayout(textAttributes: { fontFamily: string; fontSize: number }) {
  let margin = { top: 0, right: 0, bottom: 0, left: 0 };
  let columnGap = { vertical: 0, horizontal: 0 };
  let columnPadding = { top: 0, right: 0, bottom: 0, left: 0 };
  let minimumTextWidth = 100;
  let maxColumns = 5;

  const layout = (viewportWidth: number, data: any[]) => {
    const dataWithCoordinates = new Array(data.length);
    const rowBottoms: number[] = [];

    const maxCols = computeMaximumAvailableColumns(
      viewportWidth,
      margin,
      columnPadding,
      columnGap,
      minimumTextWidth
    );

    const maxRows = Math.ceil(data.length / maxCols);
    const horizontalMargin = margin.left + margin.right;
    const totalGap = columnGap.horizontal * (maxCols - 1);
    const columnWidth = (viewportWidth - horizontalMargin - totalGap) / maxCols;

    for (let row = 0; row < maxRows; row++) {
      let rowHeight = 0;

      for (let col = 0; col < maxCols; col++) {
        const index = row * maxCols + col;

        if (index >= data.length) {
          break;
        }

        const text = data[index];

        const wrappedTextRows = wrapText(
          textAttributes.fontFamily,
          textAttributes.fontSize,
          text,
          columnWidth - (columnPadding.left + columnPadding.right)
        );

        const elementHeight = wrappedTextRows[wrappedTextRows.length - 1].y;

        rowHeight = Math.max(elementHeight, rowHeight);

        const elementX = margin.left + columnWidth * col + columnGap.horizontal * col;
        const elementY = row > 0 ? rowBottoms[row - 1] + columnGap.vertical : margin.top;

        dataWithCoordinates[index] = {
          x: elementX,
          y: elementY,
          wrappedText: wrappedTextRows
        };
      }

      rowBottoms[row] = (row > 0 ? rowBottoms[row - 1] + columnGap.vertical : margin.top) + rowHeight;
    }

    return dataWithCoordinates;
  };

  /**
   * Layout configurators
   */

  layout.margin = function (newMargin: { top: number; right: number; bottom: number; left: number }) {
    if (arguments.length === 0) {
      return margin;
    }

    margin = newMargin;
    return layout;
  };

  layout.columnGap = function (newGap: { horizontal: number; vertical: number }) {
    if (arguments.length === 0) {
      return columnGap;
    }

    columnGap = newGap;
    return layout;
  };

  layout.columnPadding = function (newPadding: { top: number; right: number; bottom: number; left: number }) {
    if (arguments.length === 0) {
      return columnPadding;
    }

    columnPadding = newPadding;
    return layout;
  };

  layout.maxColumns = function (columns: number) {
    if (arguments.length === 0) {
      return maxColumns;
    }

    maxColumns = columns;
    return layout;
  };

  layout.minimumTextWidth = function (textWidth: number) {
    if (arguments.length === 0) {
      return minimumTextWidth;
    }

    minimumTextWidth = textWidth;
    return layout;
  };

  return layout;
}

export function getTranslation(tickDate: string) {
  return _.replace(tickDate, /[a-zA-Z]+/, function (match) {
    const dateLabel = _.get(DATE_LABEL_TRANSLATIONS, match);
    // These translations need to be defined inside the function
    // because I18n does not get hydrated in embeds until after
    // they were getting set. By moving them into the function,
    // I18n has time to get hydrated. Relates to EN-18831
    return dateLabel ? I18n.t(`shared.visualizations.common.date.${dateLabel}`) : match;
  });
}

/**
 * Internal helper function for calculating maximum number of columns which fits into given viewport width.
 */
function computeMaximumAvailableColumns(
  viewportWidth: number,
  margin: { top: number; right: number; bottom: number; left: number },
  itemPadding: { top: number; right: number; bottom: number; left: number },
  columnGap: { horizontal: number; vertical: number },
  minimumWidth: number
): number {
  const horizontalMargin = margin.left + margin.right;
  const horizontalPadding = itemPadding.left + itemPadding.right;
  const horizontalGap = columnGap.horizontal;

  const columns =
    (viewportWidth - horizontalGap - horizontalMargin) / (minimumWidth + horizontalGap + horizontalPadding);
  return Math.floor(columns);
}

/**
 * Returns visual size of the given element. Use this to calculate the element's size
 * if element is not attached to document yet.
 */
export function calculateElementSize(element: SVGGraphicsElement): { width: number; height: number } {
  const svg = getTemporarySvg();

  svg.appendChild(element);

  const bbox = element.getBBox?.();
  const size = { width: bbox.width, height: bbox.height };

  svg.removeChild(element);
  return size;
}

/**
 * Text Processing Helpers
 */

/**
 * Returns calculated text size for given font family and font size. Results will be
 * cached.
 *
 * @param {String} fontFamily
 * @param {Number} fontSize
 * @param {String} text
 * @return {{width: Number, height: Number}}
 */
export const calculateTextSize = _.memoize(
  (fontFamily, fontSize, text) => {
    const textElement = getTemporarySvgElement('calculateTextSize', 'text', {
      'font-family': fontFamily,
      'font-size': fontSize
    });

    textElement.textContent = text;

    // @ts-expect-error TS(2339) FIXME: Property 'getBBox' does not exist on type 'SVGElem... Remove this comment to see the full error message
    const bbox = textElement?.getBBox?.() ?? { width: 0, height: 0 };

    return {
      width: bbox.width,
      height: bbox.height
    };
  },
  (fontFamily, fontSize, text) => `${fontFamily} ${fontSize} ${text}`
);

/**
 * Returns an array of strings with their relative positions. Lines are
 * split by the word boundaries.
 */
export function wrapText(
  fontFamily: string,
  fontSize: number,
  text: string,
  availableWidth: number,
  testElementSize?: { width: number; height: number }
): { text: string; x: number; y: number }[] {
  let textSize = calculateTextSize(fontFamily, fontSize, text);

  if (testElementSize) {
    textSize = testElementSize;
  }
  const rows = textSize.width / availableWidth;
  const maxCharactersPerRow = Math.floor(text.length / rows);

  const chunks = StringHelpers.partitionByWordBoundaries(text, maxCharactersPerRow);

  return chunks.map((chunk: string, i: number) => ({
    text: chunk,
    x: 0,
    y: (i + 1) * textSize.height
  }));
}

/**
 * DOM Functionality
 */
export function createSvgElement(tagName: string): SVGElement {
  return document.createElementNS('http://www.w3.org/2000/svg', tagName);
}

/**
 * Common Internal Functions
 */

let _temporarySvg: SVGElement | null = null;
let _temporarySvgTimeout: NodeJS.Timeout | null = null;
/**
 * Returns an Svg element, which is added to the body. To reduce the number of DOM
 * additions and removals, it keeps temporary element in the body for a while and
 * removes after it.
 */
function getTemporarySvg(): SVGElement {
  if (_temporarySvg === null) {
    _temporarySvg = createSvgElement('svg');
    _temporarySvg.style.position = 'fixed';
    _temporarySvg.style.opacity = '0';
    _temporarySvg.style.pointerEvents = 'none';
    _temporarySvg.style.width = '1000px';
    _temporarySvg.style.height = '1000px';
  }

  if (!_temporarySvg.parentElement) {
    document.body.appendChild(_temporarySvg);
  }

  if (_temporarySvgTimeout !== null) {
    clearTimeout(_temporarySvgTimeout);
  }

  _temporarySvgTimeout = setTimeout(() => {
    if (_temporarySvg?.parentElement) {
      _temporarySvg.parentElement.removeChild(_temporarySvg);
    }
  }, 300000);

  return _temporarySvg;
}

/**
 * Returns an element from temporary svg which matches the given id. If the element
 * with the given id is not exist, it will be created. If the given attributes are
 * different than the previously created element, element's attributes will be updated.

 */
function getTemporarySvgElement(
  id: string,
  tagName: string,
  attributes: { [key: string]: string }
): SVGElement {
  const svg = getTemporarySvg();
  let element: SVGElement | null = svg.querySelector(`${tagName}#${id}`);

  if (!element) {
    element = createSvgElement(tagName);
    element.id = id;

    Object.keys(attributes).forEach((attributeName) => {
      element!.setAttribute(attributeName, attributes[attributeName]);
    });

    svg.appendChild(element);
  } else {
    Object.keys(attributes).forEach((attributeName) => {
      const attributeValue = attributes[attributeName];

      if (element!.getAttribute(attributeName) !== attributeValue) {
        element!.setAttribute(attributeName, attributeValue);
      }
    });
  }

  return element;
}

interface HideOffscreenDimensionLabelsOptions {
  viewportSvg: d3.Selection<SVGGraphicsElement>;
  lastRenderedZoomTranslate: number;
  /** Optional: if omitted we only hide dimensionLabels beyond the left axis */
  viewportWidth?: number;
}
export function hideOffscreenDimensionLabels({
  viewportSvg,
  lastRenderedZoomTranslate,
  viewportWidth
}: HideOffscreenDimensionLabelsOptions) {
  // NOTE: The below function depends on `this` being set by d3, so it is
  // not possible to use the () => {} syntax here.
  viewportSvg.selectAll('.x.axis .tick').each(function () {
    const tick = d3.select(this);
    const x = d3.transform(tick.attr('transform')).translate[0];

    tick.classed('tick-hidden', () => {
      const translateX = lastRenderedZoomTranslate + x;
      return translateX < 0 || (!_.isNil(viewportWidth) && translateX > viewportWidth);
    });
  });
}
