import React, { useImperativeHandle, forwardRef } from 'react';

import I18n from 'common/i18n';
import { ITooltipParams } from '@ag-grid-community/core';
import { getAgTableHeaderName, plainTextDataFormatter } from './helpers/TableColumnFormatting';
import { ViewColumn } from 'common/types/viewColumn';
import { CustomAgGridContext } from 'common/types/agGrid/context';
import { isEqual } from 'lodash';

type SocrataTooltipProps = {
  domain: string;
  datasetUid: string;
  isIndented?: boolean;
} & ITooltipParams<any, any, Partial<CustomAgGridContext>>;

const SocrataTooltip = forwardRef((props: SocrataTooltipProps, ref) => {
  const { location, colDef, domain, datasetUid, node } = props;
  const ctx = props.context;
  const allColumnMetadata = ctx.columnMetadata ?? [];
  const columnFormats = ctx.columnFormats ?? {};
  const nonStandardAggregations = ctx.nonStandardAggregations ?? [];
  const isSubtotalCell = node?.footer && isEqual(Object.values(props.data)[0], props.value);

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-ag-grid-flyout-container'];
      }
    };
  });

  let flyoutData;
  if (location === 'header' && props.isIndented) {
    const fieldName = props?.api.getRowGroupColumns()[0].getColDef().field as string;
    const localColumnMetadata = allColumnMetadata.find((c) => c.fieldName === fieldName) as ViewColumn;
    const columnFormat = columnFormats[fieldName];
    const headerDisplayName = getAgTableHeaderName(columnFormat, localColumnMetadata);
    flyoutData = <div className="socrata-flyout-content">{headerDisplayName}</div>;
  } else if (location === 'header') {
    flyoutData = <div className="socrata-flyout-content">{colDef?.headerName}</div>;
  } else if (location === 'cell') {
    // we can only do this because plainTextDataFormatter calls renderCellHTML which returns *escaped* text!
    const content = props.value.hasOwnProperty('url')
      ? props.value.url
      : plainTextDataFormatter(
          allColumnMetadata,
          nonStandardAggregations,
          props,
          domain,
          datasetUid,
          columnFormats
        );
    if (isSubtotalCell) {
      const subtotalLabel = I18n.t('shared.visualizations.charts.table.subtotal');
      const subtotalText = `${subtotalLabel + ' ' + content}`;
      flyoutData = (
        <div className="socrata-flyout-content" dangerouslySetInnerHTML={{ __html: subtotalText }}></div>
      );
    } else {
      flyoutData = (
        <div className="socrata-flyout-content" dangerouslySetInnerHTML={{ __html: content }}></div>
      );
    }
  }

  if (flyoutData) {
    return (
      <div id="socrata-flyout" className="custom-ag-grid-flyout dark northwest">
        <div className="socrata-flyout-hint"></div>
        {flyoutData}
      </div>
    );
  } else {
    return null;
  }
});

SocrataTooltip.displayName = 'SocrataTooltip';

export default SocrataTooltip;
