import _ from 'lodash';

export const CLUSTER_BUCKETS = Object.freeze({
  SMALL: 0,
  MEDIUM: 100,
  LARGE: 1000
});

export const HIGHLIGHT = Object.freeze({
  COLOR: '#ffffff',
  LINE_WIDTH: 3,
  LINE_BLUR: 0.5,
  LINE_CAP: 'round',
  OPACITY: { MIN: 0, MAX: 1, DEFAULT: 0.6 },
  POINT_RADIUS: 2,
  SHAPE_WIDTH: 1
});

export const DEFAULT_LAYER_VISIBLE = true;

// If the user selects a point column with no values or a dataset with no records
// for map visualization, then the map will be defaulted to this location.
export const DEFAULT_MAP_CENTER = [-122.3321, 47.6062]; // Seattle

// For old single layer maps, the first series wont be set as primary.
// If no layer is set as primary, we take the first layer as the pimary.
export const DEFAULT_PRIMARY_SERIES_INDEX = 0;
export const DEFAULT_SPIDERFY_FEATURES_COUNT = 100;
export const DEFAULT_STACK_RADIUS_PADDING = 2;
export const DROP_PIN = Object.freeze({
  TEXT_FIELD: 'map-marker-alt',
  TEXT_SIZE: 20,
  TEXT_COLOR: '#ff0000'
});

export const FIT_BOUNDS_PADDING = 20;

export const GEO_LOCATE_CONTROL_OPTIONS = Object.freeze({
  positionOptions: Object.freeze({
    enableHighAccuracy: true
  }),
  trackUserLocation: true
});

export const LAYER_TOGGLING_THROTTLE_WAIT = 300;

export const MAP_CONTROLS_POSITION = Object.freeze({
  NAVIGATION: 'bottom-left',
  GEO_LOCATE: 'bottom-left',
  GEO_CODER: 'top-left',
  TOGGLE_LAYER: 'top-left'
});


export enum MAP_TYPES {
  POINT_MAP = 'pointMap',
  LINE_MAP = 'lineMap',
  BOUNDARY_MAP = 'boundaryMap'
}

export const MAP_EVENTS = Object.freeze({
  FINISHED_SPIDER_DATA: 'finished-loading-spider-data',
  LOADING_SPIDER_DATA: 'loading-spider-data',
  TOGGLE_MAP_LAYER: 'toggle-map-layer'
});

export const MAX_ZOOM = 24;
export const MAP_CANVAS_CLASSNAME = 'mapboxgl-canvas';
export const MAP_HIGHLIGHT_CONTAINER_CLASSNAME = 'high-light-container';
export const MAP_CANVAS_OPACITY = 0.25;
export const MIN_ZOOM = 0;
export const NO_COLOR = 'rgba(0, 0, 0, 0)';
export const QUERY_TIMEOUT_SECONDS = 60;

export const RADIUS_FILTER_CIRCLE_COLOR = '#ce453c';
export const RADIUS_FILTER_CIRCLE_PADDING = 20; // in pixels
export const RADIUS_FILTER_MAP_PANNING_DURATION = 300; // in milliseconds

// To tune the charm size with respect to the circle size.
export const RADIUS_TO_CHARM_FONT_SIZE = 1.0;
// Ratio used to calculate the charm image height for given circle size.
export const RADIUS_TO_CHARM_IMAGE_SIZE = RADIUS_TO_CHARM_FONT_SIZE * 1.05;

export const SHAPE_GEOMETRY_COLUMN = 'the_geom';
export const SHAPE_GEOMETRY_SECONDARY_COLUMN = 'polygon';
export const SHAPE_GEOMETRY_VALID_DATA_TYPES = ['polygon', 'multipolygon'];
export const SMALL_SIZE_MAP_WIDTH = 480;

export const TILE_CALL_DELAY_ON_ZOOM = 500; // in milliseconds

export const TO_METERS_CONVERSION_FACTORS = {
  miles: 1609.34
};

// Returns simplification/precision value to be used for each zoom level based on given factor.
// {
//    "1":"0.5",
//    "2":"0.25",
//    ...
//    "24":"0.00000006"
// }
const zoomToLatLngPrecision = (simplificationFactor: number) => {
  return _.chain(_.range(MIN_ZOOM, MAX_ZOOM + 1))
    .map((zoom) => {
      const precision = 1 / Math.pow(simplificationFactor, zoom);
      return [zoom.toString(), precision.toFixed(8)];
    })
    .fromPairs()
    .value();
};

export const SIMPLIFICATION_CONFIGURATION = _.reduce(
  { '1': 3, '2': 2, '3': 1.8 },
  (configs, factor, level) => {
    configs[level] = Object.freeze({
      snapPrecision: Object.freeze(zoomToLatLngPrecision(factor)),
      simplifyPrecision: Object.freeze(zoomToLatLngPrecision(factor))
    });
    return configs;
  },
  {}
);
