// Vendor Imports
import _ from 'lodash';
import $ from 'jquery';
import React, { Component, SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';

// Project Imports
import Picklist, { PicklistOption } from '../Picklist';
import SocrataIcon, { IconName } from '../SocrataIcon';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './Co... Remove this comment to see the full error message
import ComputedColumnSelector from './ComputedColumnSelector';
import Button, { VARIANTS, SIZES } from 'common/components/Button';
import FlyoutFactory from 'common/components/legacy/Flyout';
import {
  ENTER,
  ESCAPE,
  SPACE,
  isOneOfKeys,
  isolateEventByKeys
} from 'common/dom_helpers/keycodes_deprecated';
import { getIconForDataType } from 'common/icons';
import I18n from 'common/i18n';
import { Filter, FilterBarColumn } from './types';

// Constants
const OPTION_TYPE_COLUMN = 'column';
const OPTION_TYPE_REGION = 'region';
const scope = 'shared.components.filter_bar';

interface AddFilterProps {
  columns: FilterBarColumn[];
  computedColumns: FilterBarColumn[];
  disabled?: boolean;
  disabledMessage?: string;
  onClickColumn: (column: FilterBarColumn) => void;
}

interface AddFilterState {
  isChoosingColumn: boolean;
  searchTerm: string;
}

export class AddFilter extends Component<AddFilterProps, AddFilterState> {
  bodyClickHandler: (event: MouseEvent) => void;
  bodyEscapeHandler: (event: KeyboardEvent) => void;

  addFilterButton: HTMLDivElement;
  element: HTMLDivElement;

  constructor(props: AddFilterProps) {
    super(props);

    this.state = {
      isChoosingColumn: false,
      searchTerm: ''
    };

    _.bindAll(this, [
      'onChangeSearchTerm',
      'onClickColumn',
      'onClickRegion',
      'onKeyDownAddFilterButton',
      'renderColumnOption',
      'renderColumnsContainer',
      'renderComputedColumnSelector',
      'renderPicklist',
      'renderSearch',
      'toggleColumnPicklist'
    ]);
  }

  componentDidMount() {
    this.bodyClickHandler = (event: MouseEvent) => {
      const el = ReactDOM.findDOMNode(this);
      if (this.state.isChoosingColumn && !el?.contains(event.target as Node)) {
        this.toggleColumnPicklist(event);
      }
    };

    this.bodyEscapeHandler = (event: KeyboardEvent) => {
      if (this.state.isChoosingColumn && event.keyCode === ESCAPE) {
        this.toggleColumnPicklist();
        this.addFilterButton.focus();
      }
    };

    document.body.addEventListener('click', this.bodyClickHandler);
    document.body.addEventListener('keyup', this.bodyEscapeHandler);

    if (this.shouldShowDisabledFlyout()) {
      new FlyoutFactory(this.element);
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.bodyClickHandler);
    document.body.removeEventListener('keyup', this.bodyEscapeHandler);
  }

  onChangeSearchTerm(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ searchTerm: event.target.value });
  }

  onClickColumn(column: FilterBarColumn) {
    this.setState({ searchTerm: '' });
    this.props.onClickColumn(column);
    this.toggleColumnPicklist();
  }

  onClickComputedColumnsBack() {
    $('.add-filter-slider').removeClass('regions-opened');
  }

  onClickRegion() {
    $('.add-filter-slider').addClass('regions-opened');
    setTimeout(() => {
      $('.add-filter-picklist-suggested-options')
        .find('.picklist-option')
        .removeClass('picklist-option-selected');
    });
  }

  onKeyDownAddFilterButton(event: React.KeyboardEvent) {
    isolateEventByKeys(event, [ENTER, SPACE]);

    if (isOneOfKeys(event, [ENTER, SPACE])) {
      this.toggleColumnPicklist(event);
    }
  }

  toggleColumnPicklist(event?: Event | SyntheticEvent) {
    if (event) {
      event.preventDefault();
    }

    this.setState({ isChoosingColumn: !this.state.isChoosingColumn });
  }

  renderColumnOption(column: Pick<FilterBarColumn, 'renderTypeName'>) {
    return (option: PicklistOption) => (
      <div className="filter-bar-column-option column-option">
        <SocrataIcon name={getIconForDataType(column.renderTypeName)} />
        <span>{option.title}</span>
      </div>
    );
  }

  renderRegionOption() {
    return (option: PicklistOption) => (
      <div className="filter-bar-column-option region-option">
        <SocrataIcon name={getIconForDataType('geospatial')} />
        <span>{option.title}</span>
        <SocrataIcon className="disclosure-icon" name={IconName.ArrowRight} />
      </div>
    );
  }

  renderColumnsContainer() {
    return (
      <div className="add-filter-columns-container">
        <div className="add-filter-picklist">
          {this.renderSearch()}
          <div className="add-filter-picklist-options">{this.renderPicklist()}</div>
        </div>
      </div>
    );
  }

  renderComputedColumnSelector() {
    const selectorProps = {
      computedColumns: this.props.computedColumns,
      onClickBack: this.onClickComputedColumnsBack,
      onClickColumn: this.onClickColumn
    };
    return <ComputedColumnSelector {...selectorProps} />;
  }

  renderPicklist() {
    const { columns, computedColumns } = this.props;
    const { searchTerm } = this.state;

    const picklistOptions = _.chain(columns)
      .filter((column: FilterBarColumn) => _.toLower(column.name ?? '').match(_.toLower(searchTerm)) !== null)
      .map((column: FilterBarColumn) => ({
        render: this.renderColumnOption(column),
        title: column.name,
        type: OPTION_TYPE_COLUMN,
        value: column.fieldName
      }))
      .value();

    let options: PicklistOption[] = [];

    if (computedColumns.length > 0) {
      const regionOption = {
        render: this.renderRegionOption(),
        title: I18n.t('region', { scope }),
        type: OPTION_TYPE_REGION,
        value: 'region'
      };

      options = _.concat(options, regionOption);
    }

    options = _.concat(options, picklistOptions);

    const picklistProps = {
      options,
      onSelection: (option?: PicklistOption | null) => {
        if (option?.type === OPTION_TYPE_COLUMN) {
          const column = _.find(columns, ['fieldName', option.value]);
          if (column) {
            this.onClickColumn(column);
          }
        } else if (option?.type === OPTION_TYPE_REGION) {
          this.onClickRegion();
        }
      },
      value: searchTerm
    };

    if (_.isEmpty(picklistOptions)) {
      return (
        <div className="alert warning">
          {I18n.t('shared.visualizations.panes.data.fields.columns.no_columns_match')}
        </div>
      );
    } else {
      return (
        <div className="add-filter-picklist-suggested-options">
          <Picklist {...picklistProps} />
        </div>
      );
    }
  }

  renderSearch() {
    const { searchTerm } = this.state;

    return (
      <div className="add-filter-picklist-input-container">
        <SocrataIcon name={IconName.Search} />
        <input
          className="add-filter-picklist-input"
          type="text"
          aria-label={I18n.t('search', { scope })}
          value={searchTerm || ''}
          autoFocus
          onChange={this.onChangeSearchTerm}
        />
      </div>
    );
  }

  shouldShowDisabledFlyout() {
    const { disabled, disabledMessage } = this.props;
    return disabled && disabledMessage;
  }

  renderDisabledFlyout() {
    const { disabledMessage } = this.props;

    return (
      <div id="disabled-add-filter-flyout" className="flyout flyout-hidden flyout-block-label">
        <section className="flyout-content">{disabledMessage}</section>
      </div>
    );
  }

  render() {
    const { disabled } = this.props;

    const button = (
      <div ref={(el: HTMLDivElement) => (this.addFilterButton = el)}>
        <Button
          variant={VARIANTS.ALTERNATE_2}
          size={SIZES.SMALL}
          inverse
          className="btn-add-filter"
          disabled={disabled}
          onClick={this.toggleColumnPicklist}
          onKeyDown={this.onKeyDownAddFilterButton}
        >
          {I18n.t('add_filter', { scope })}
        </Button>
      </div>
    );

    const props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> = {
      className: 'add-filter'
    };

    if (this.shouldShowDisabledFlyout()) {
      props.ref = (el: HTMLDivElement) => (this.element = el);
      props['data-flyout'] = 'disabled-add-filter-flyout';
    }

    const menus = this.state.isChoosingColumn ? (
      <div className="add-filter-menus-container">
        <div className="add-filter-menus-inner-container">
          <div className="add-filter-slider">
            {this.renderColumnsContainer()}
            {this.renderComputedColumnSelector()}
          </div>
        </div>
      </div>
    ) : null;

    return (
      <div {...props}>
        {button}
        {this.shouldShowDisabledFlyout() && this.renderDisabledFlyout()}
        {menus}
      </div>
    );
  }
}

export default AddFilter;
