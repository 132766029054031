import has from 'lodash/has';
import assert from './assert';

type PropertyKey = string | number | symbol;

type AssertHasProperties = <T, P extends PropertyKey>(object: T, name: P, message?: string) => asserts object is T & Record<P, unknown>;

const assertHasProperties: AssertHasProperties =  (object, name, message) => {
  // name can be a symbol, but we want to use it as a string
  const stringName = String(name);

  assert(
    has(object, name),
    message ||
      `'${stringName}}' property must be present. Object has properties: [${Object.keys(object).join(', ')}].`
  );
};

export default assertHasProperties;
