import _ from 'lodash';
// adding ".js" here breaks the module resolution for some reason
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'utf8... Remove this comment to see the full error message
import getUtf8ByteLength from 'utf8-byte-length';


function byteLength(str: any) {
  // chunkArrayByLength initially used TextEncode to determine the length. TextEncode would
  // coerce values to strings (e.g. null would become "null") so concatenating '' mimics
  // that behavior.
  return getUtf8ByteLength(str + '');
}

export function chunkArrayByLength(arr: any[], maxChunkLength = 1400, chunkByBytes = true) {
  const chunkFn = chunkByBytes ? byteLength : _.size;

  const res = [];
  let currentChunk: any[] = [];
  let currentChunkLength = 0;
  _.forEach(arr, (elt) => {
    currentChunk.push(elt);
    currentChunkLength += chunkFn(elt);
    if (currentChunkLength >= maxChunkLength) {
      res.push(currentChunk);
      currentChunk = [];
      currentChunkLength = 0;
    }
  });
  if (currentChunkLength !== 0) {
    res.push(currentChunk);
  }
  return res;
}
