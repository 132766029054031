import React, { FormEvent } from 'react';
import './export-dataset-modal.scss';
import {
  ForgeDialog,
  ForgeButton,
  ForgeIcon,
  ForgeScaffold,
  ForgeToolbar,
  ForgeIconButton,
  ForgeButtonToggle,
  ForgeButtonToggleGroup,
  ForgeSelect
} from '@tylertech/forge-react';
import {
  soqlRendering,
  SoQLType,
  isLeaf,
  TypedExpr,
  isTypedFunCall,
  isTypedColumnRef,
  TypedSoQLColumnRef
} from 'common/types/soql';
import { chain, isEmpty, isString, isObject, isUndefined, omitBy, map, Dictionary } from 'lodash';
import { FeatureFlags } from 'common/feature_flags';
import moment from 'moment';
import { View } from 'common/types/view';
import CopyToClipboard from 'react-copy-to-clipboard';
import { lastInChain } from 'common/explore_grid/lib/selectors';
import { QueryCompilationSucceeded, isCompilationSucceeded } from 'common/types/compiler';
import {
  getDownloadLink,
  getMimeType,
  getExportFormats,
  ExportFormat,
  IGNORED_FORMATS_FOR_DATE,
  supportsGeoExport
} from 'common/downloadLinks';
import { Option, none, some } from 'ts-option';
import UrlEndpoint, { ResourceType } from './UrlEndpoint';
// import OdataEndpoint from './OdataEndpoint';
import { fetchTranslation } from 'common/locale';
import { toTypedOverrideParamString } from 'common/core/client_context_variables';
import { ClientContextVariable } from 'common/types/clientContextVariable';
import { checkStatus } from 'common/notifications/api/helper';
import contentDisposition from 'content-disposition';
import { ToastType, showToastNow, hideToastById } from '../ToastNotification/Toastmaster';
import { ForgeRadio } from '@tylertech/forge-react';
import { ExportStateFunctions, filterRowData, getTotalRowCount, shouldRenderWarning } from './ExportHelper';
import { ForgeOption } from '@tylertech/forge-react';
import eventBus from 'common/visualizations/views/agGridReact/helpers/EventBus';
import { ForgeInlineMessage } from '@tylertech/forge-react';

export const t = (k: string) => fetchTranslation(k, 'shared.explore_grid.export_dataset_modal');
const translationWithOption = (k: string, option: any) =>
  fetchTranslation(k, 'shared.explore_grid.export_dataset_modal', undefined, option);
const toastHideId = 'export-modal-toast';

interface ResourceState {
  apiResources: ResourceType[];
  odataResources: ResourceType[];
  downloadResources: ResourceType[];
  selectedApiResource: ResourceType;
  selectedOdataResource: ResourceType;
  selectedDownloadResource?: ResourceType;
}

const protocolAndHost = () => `${window.location.protocol}//${window.location.host}`;

const queryUrl = (
  ff: string,
  query: Option<string>,
  type: 'csv' | 'json' | 'geojson',
  clientContextVariables: ClientContextVariable[]
) => {
  let url = `${protocolAndHost()}/resource/${ff}.${type}`;
  if (query.nonEmpty || clientContextVariables.length) {
    url = `${url}?`;
  }
  if (query.nonEmpty) {
    url = `${url}$query=${encodeURIComponent(query.get)}`;
  }
  if (clientContextVariables.length) {
    url = `${url}&${toTypedOverrideParamString(clientContextVariables)}`;
  }
  return url;
};

const makeResourcesApi = (
  view: View,
  query: Option<string>,
  clientContextVariables: ClientContextVariable[],
  useResourceName?: boolean
): ResourceType[] => {
  // I believe we would want to set this based if the user is using a resource_name
  // We optionally use resource name or four-four
  const identifier = useResourceName && view?.resourceName ? view.resourceName : view.id;

  const resourceLinks = [
    {
      label: t('csv'),
      value: 'csv' as ExportFormat,
      url: queryUrl(identifier, query, 'csv', clientContextVariables),
      defaultType: false
    },
    {
      label: t('json'),
      value: 'json' as ExportFormat,
      url: queryUrl(identifier, query, 'json', clientContextVariables),
      defaultType: true
    }
  ];

  if (supportsGeoExport(view)) {
    resourceLinks.push({
      label: t('geojson'),
      value: 'geojson' as ExportFormat,
      url: queryUrl(identifier, query, 'geojson', clientContextVariables),
      defaultType: false
    });
  }
  return resourceLinks;
};

const makeResourcesOdata = (view: View, odataSelectClause = ''): ResourceType[] => {
  const url = (V2orV4ApiPath: string) =>
    `${protocolAndHost()}/${V2orV4ApiPath}/${view.id}${odataSelectClause}`;
  const resourceLinks = [
    {
      label: 'OData V4',
      value: 'odataUrlV4' as ExportFormat,
      url: url('api/odata/v4'),
      defaultType: true
    },
    {
      label: 'OData V2',
      value: 'odataUrlV2' as ExportFormat,
      url: url('Odata.svc'),
      defaultType: false
    }
  ];

  return resourceLinks;
};

const makeResourcesDownloads = (
  view: View,
  queryString: Option<string>,
  exportFormats: ExportFormat[],
  clientContextVariables: ClientContextVariable[],
  fourfour?: string,
  revisionSeq?: number
): ResourceType[] => {
  return exportFormats.map((format) => {
    const url =
      getDownloadLink(view.id, downloadOptions(view, format, queryString, fourfour, revisionSeq)) || '';
    return {
      label: t(format),
      value: format,
      url: clientContextVariables.length ? `${url}&${toTypedOverrideParamString(clientContextVariables)}` : url,
      defaultType: format === 'csv'
    };
  });
};

function hasComputedColumnHelper(expr: TypedExpr): boolean {
  if (isTypedFunCall(expr)) {
    return hasComputedColumn(expr.args);
  } else if (isTypedColumnRef(expr)) {
    // tsc doesn't realize we just have a TypedSoQLColumnRef here
    return (expr as TypedSoQLColumnRef).value.startsWith(':@');
  } else {
    return false;
  }
}

function hasComputedColumn(exprs: TypedExpr[]): boolean {
  return exprs.some((expr) => hasComputedColumnHelper(expr));
}

const getResourceState = (
  view: View,
  query: Option<QueryCompilationSucceeded>,
  clientContextVariables: ClientContextVariable[],
  queryString: Option<string>,
  isTableViz: boolean,
  fourfour?: string,
  revisionSeq?: number,
  currentResourceState?: ResourceState,
  useResourceName?: boolean,
  odataSelectClause?: string
) => {
  // if there's a query, do this...
  const formats = query
    .map((qs) => {
      const selectionTypes = lastInChain(qs.analyzed).selection.reduce((acc: SoQLType[], selectedExpr) => {
        if (selectedExpr.expr.soql_type !== null) {
          return [selectedExpr.expr.soql_type, ...acc];
        }
        return acc;
      }, []);

      // :@ is invalid in XML. If the select includes a computed column, core will
      // mark it as hidden on xml export, causing query-coordinator to throw a
      // column-not-found error if we try to select it. This happens even if the
      // computed column is aliased.. So if a computed column is in the select,
      // do not show XML export as an option.
      function invalidSelectForXML(q: QueryCompilationSucceeded): boolean {
        // don't care about other compilation states since export is disabled for them
        if (isCompilationSucceeded<QueryCompilationSucceeded>(q)) {
          if (isLeaf(q.analyzed)) {
            // have to recursively crawl ast because user could pass select computed column
            // to a function
            return hasComputedColumn(q.analyzed?.value?.selection.map((select) => select.expr));
          }
        }
        return false;
      }

      return getExportFormats(view, isTableViz, invalidSelectForXML(qs), selectionTypes);
    })
    .getOrElseValue(getExportFormats(view, isTableViz));

  let maybeQueryString;
  // We either use the queryString or we use the query.
  if (queryString.isDefined) {
    // We are using the select and where clauses
    maybeQueryString = queryString;
  } else {
    // We are using the query
    maybeQueryString = query.map((qs) => soqlRendering.unwrap(qs.rendering));
  }

  const downloadResources = makeResourcesDownloads(
    view,
    maybeQueryString,
    formats,
    clientContextVariables,
    fourfour,
    revisionSeq
  );

  const apiResources = makeResourcesApi(view, maybeQueryString, clientContextVariables, useResourceName);

  const OdataResources = makeResourcesOdata(view, odataSelectClause);

  const getSelectedDownloadResource = () => {
    if (currentResourceState) {
      // see if the selected resource is in our list, otherwise fall through and use the default resource
      const foundResource = downloadResources.find(
        (v) => v.value === currentResourceState.selectedDownloadResource?.value
      );
      if (foundResource) return foundResource;
    }
    return downloadResources.find((v) => v.defaultType);
  };

  const getSelectedApiResource = () => {
    if (currentResourceState) {
      const foundResource = apiResources.find(
        (v) => v.value === currentResourceState.selectedApiResource.value
      );
      if (foundResource) return foundResource;
    }
    return apiResources.find((v) => v.defaultType);
  };

  const getSelectedOdataResource = () => {
    if (currentResourceState) {
      const foundResource = OdataResources.find(
        (v) => v.value === currentResourceState.selectedOdataResource.value
      );
      if (foundResource) return foundResource;
    }
    return OdataResources.find((v) => v.defaultType);
  };

  const resourceState: ResourceState = {
    selectedDownloadResource: getSelectedDownloadResource(),
    apiResources: apiResources,
    odataResources: OdataResources,
    downloadResources: downloadResources,
    // there will always be a defaultType because we hard coded it
    // @ts-expect-error TS(2322) FIXME: Type 'ResourceType | undefined' is not assignable ... Remove this comment to see the full error message
    selectedApiResource: getSelectedApiResource(),
    // there will always be a defaultType because we hard coded it
    // @ts-expect-error TS(2322) FIXME: Type 'ResourceType | undefined' is not assignable ... Remove this comment to see the full error message
    selectedOdataResource: getSelectedOdataResource()
  };

  return resourceState;
};

const downloadOptions = (
  view: View,
  format: ExportFormat,
  queryString: Option<string>,
  fourfour?: string,
  revisionSeq?: number
) => {
  let queryParams: Dictionary<any> = omitBy(
    {
      query: queryString.nonEmpty ? queryString.get : undefined,
      fourfour: fourfour,
      revisionSeq: revisionSeq
    },
    isUndefined
  );

  if (queryString.nonEmpty) {
    queryParams = {
      ...queryParams,
      read_from_nbe: true,
      version: 2.1
    };
  }

  // this parameter ensures the browser doesn't use a cached version after an update
  if (view) {
    queryParams.cacheBust = chain([view.rowsUpdatedAt, view.createdAt, view.viewLastModified])
      .compact()
      .max()
      .value();
  }

  if (IGNORED_FORMATS_FOR_DATE.indexOf(format) === -1) {
    queryParams.date = moment().format('YYYYMMDD');
  }

  return {
    queryParams,
    cname: window.location.hostname,
    format
  };
};

export enum TOGGLE_OPTIONS {
  DOWNLOAD_FILE = 'download_file',
  API_ENDPOINT = 'api_endpoint',
  ODATA_ENDPOINT = 'odata_endpoint'
}

export interface StateProps {
  query: Option<QueryCompilationSucceeded>;
  view: View;
  clientContextVariables: ClientContextVariable[];
}

export interface QueryStringObject {
  selectClause: string;
  odataSelectClause?: string;
  whereClause: string;
}

type Props = StateProps & {
  defaultToggleOption?: TOGGLE_OPTIONS;
  revisionSeq?: number;
  fourfour?: string;
  bodyText?: string;
  onDismiss: () => void;
  // Optional as you can use the query prop instead.
  // Or pass nothing and it will export all the data on the view
  queryStringClause?: string | QueryStringObject;
  showDataToggles?: boolean;
  // Used by when showDataToggles is true. Pass this in if you have it or the dialog will fetch it.
  totalRowCount?: number;
  // used by API Endpoint
  apiFoundryUrl?: string;
  vizUid?: string;
  isTableViz: boolean | false;
};

export interface State {
  toggleOption: TOGGLE_OPTIONS;
  resourceState: ResourceState;
  rowCountFiltered?: number;
  rowCountAll?: number;
  selectedFiltered: boolean;
  showFilteredDataOption: boolean;
  useResourceName: boolean;
}

export default class ExportModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      toggleOption: this.props.defaultToggleOption || TOGGLE_OPTIONS.DOWNLOAD_FILE,
      resourceState: getResourceState(
        props.view,
        props.query,
        props.clientContextVariables,
        none,
        props.isTableViz,
        props.fourfour,
        props.revisionSeq
      ),
      rowCountFiltered: 0,
      rowCountAll: 0,
      selectedFiltered: this.shouldShowFilteredDownload(),
      showFilteredDataOption: this.shouldShowFilteredDownload(),
      useResourceName: false
    };
  }

  componentDidMount() {
    const { view, totalRowCount, showDataToggles, queryStringClause } = this.props;
    const queryString = isObject(queryStringClause)
      ? `${queryStringClause.selectClause} ${queryStringClause.whereClause}`
      : queryStringClause;
    if (queryString) {
      this.updateResourceStateQueryString();

      const exportStateFunctions: ExportStateFunctions = {
        setRowCountFiltered: this.setRowCountFiltered
      };

      filterRowData(view, exportStateFunctions, queryString);
    }

    // Set the total row count for the toggles
    if (showDataToggles) {
      if (totalRowCount) {
        this.setRowCountAll(totalRowCount);
      } else {
        getTotalRowCount(view, this.setRowCountAll);
      }
    }
  }

  setRowCountAll = (rowCount: number) => {
    this.setState({ rowCountAll: rowCount });
  };

  setRowCountFiltered = (rowCount: number) => {
    this.setState({ rowCountFiltered: rowCount });
  };

  handleToggleChange = (value: string) => {
    this.setState({ toggleOption: value as TOGGLE_OPTIONS });
  };

  handleRadioFilterChange = (event: FormEvent) => {
    const target = event.target as HTMLInputElement;
    const selectedFiltered = target.value !== 'all';
    this.setState({ selectedFiltered: selectedFiltered }, () => this.updateResourceStateQueryString());
  };

  handleUseResourceNameChange = (useName: boolean) => {
    this.setState({ useResourceName: useName }, () => this.updateResourceStateQueryString());
  };

  updateResourceStateQueryString() {
    const { queryStringClause } = this.props;
    const odataQueryString = isObject(queryStringClause) ? queryStringClause.odataSelectClause : undefined;
    let queryString;
    // We should only be able to select filtered when there is a queryString
    if (this.state.selectedFiltered && queryStringClause) {
      // Here we are exporting filtered
      queryString = isString(queryStringClause)
        ? some(queryStringClause)
        : some(`${queryStringClause.selectClause} ${queryStringClause.whereClause}`);
    } else {
      // If the export is on a viz, then respect the column selection
      queryString = isObject(queryStringClause) ? some(queryStringClause.selectClause) : none;
    }
    this.setState({
      resourceState: getResourceState(
        this.props.view,
        this.props.query,
        this.props.clientContextVariables,
        queryString,
        this.props.isTableViz,
        this.props.fourfour,
        this.props.revisionSeq,
        this.state.resourceState,
        this.state.useResourceName,
        odataQueryString
      )
    });
  }

  onDownload = () => {
    hideToastById(toastHideId);
    if (this.state.resourceState.selectedDownloadResource) {
      showToastNow({
        type: ToastType.FORGE_DEFAULT,
        content: t('data_exported'),
        timeout: Infinity,
        hideId: toastHideId
      });
      if (this.state.resourceState.selectedDownloadResource?.value === 'xlsx') {
        eventBus.dispatch(`exportGridData-${this.props.vizUid}`, {
          selectedFiltered: this.state.selectedFiltered
        });
        this.props.onDismiss();
      } else {
        this.downloadFileRequest(this.state.resourceState.selectedDownloadResource);
      }
    }
  };

  onCopyToast = (content: string) => {
    showToastNow({
      type: ToastType.FORGE_DEFAULT,
      content,
      hideId: toastHideId
    });
  };

  onCopyApiToast = () => this.onCopyToast(t('api_copied'));
  onCopyOdataToast = () => this.onCopyToast(t('odata_copied'));

  filteringRadioButtons = (odata = false) => {
    const { rowCountFiltered, rowCountAll, resourceState } = this.state;
    const selectedResourceValue = resourceState?.selectedDownloadResource?.value;

    if (!this.props.showDataToggles) return null;

    const selectedFiltered = odata ? false : this.state.selectedFiltered;

    return (
      <div>
        {shouldRenderWarning(selectedResourceValue, selectedFiltered, rowCountFiltered, rowCountAll) && (
          <ForgeInlineMessage theme="warning" className="partial-export-message">
            <ForgeIcon name="warning" slot="icon" />
            <div className="message-title" slot="title">
              {t('partial_export_title')}
            </div>
            <div>{t('partial_export_body')}</div>
          </ForgeInlineMessage>
        )}
        <div
          className="filtered-radio-group"
          data-testid="radio-group-filtering"
          role="radiogroup"
          aria-label={t('dialog_radio')}
          onChange={this.handleRadioFilterChange}
        >
          {this.state.showFilteredDataOption && !odata && (
            <ForgeRadio className="filtered-data">
              <input
                type="radio"
                id="radio-filtered"
                name="radio-filters"
                data-testid="radio-option-filtered"
                value="filtered"
                defaultChecked={selectedFiltered}
              />
              <label htmlFor="radio-filtered">
                {isEmpty(window.STORY_DATA)
                  ? translationWithOption('modified_data', { rowCount: rowCountFiltered })
                  : translationWithOption('filtered_data', { rowCount: rowCountFiltered })}
              </label>
            </ForgeRadio>
          )}
          <ForgeRadio className="all-data">
            <input
              type="radio"
              id="radio-all"
              name="radio-filters"
              data-testid="radio-option-all"
              value="all"
              defaultChecked={!selectedFiltered}
            />
            <label htmlFor="radio-all">{translationWithOption('all_data', { rowCount: rowCountAll })}</label>
          </ForgeRadio>
        </div>
      </div>
    );
  };

  // we only want to show the warning when currently export is over 1000
  shouldShowApiWarning() {
    if (this.state.selectedFiltered) {
      return (this.state.rowCountFiltered || 0) > 1000;
    } else {
      return (this.state.rowCountAll || 0) > 1000;
    }
  }

  apiEndpoint = () => {
    return (
      <div>
        <UrlEndpoint
          onCopy={this.onCopyApiToast}
          onSelectedUrlChange={this.handleSelectedUrlChange}
          resources={this.state.resourceState.apiResources}
          selectedResource={this.state.resourceState.selectedApiResource}
          foundryUrl={this.props.apiFoundryUrl}
          filteringRadioButtons={this.filteringRadioButtons()}
          useResourceName={this.state.useResourceName}
          onResourceNameUpdate={
            // we only want to show the option to add resource name when there is one available
            this.props.view?.resourceName ? this.handleUseResourceNameChange : undefined
          }
          showApiLimitWarning={this.shouldShowApiWarning()}
          apiOrOdata="api"
          isOdata={false}
        />
      </div>
    );
  };

  _OdataEndpoint = () => {
    return (
      <div>
        <UrlEndpoint
          onCopy={this.onCopyOdataToast}
          onSelectedUrlChange={this.handleOdataVersionChange}
          resources={this.state.resourceState.odataResources}
          selectedResource={this.state.resourceState.selectedOdataResource}
          filteringRadioButtons={this.filteringRadioButtons(true)}
          useResourceName={this.state.useResourceName}
          onResourceNameUpdate={
            // we only want to show the option to add resource name when there is one available
            this.props.view?.resourceName ? this.handleUseResourceNameChange : undefined
          }
          showApiLimitWarning={false}
          apiOrOdata="odata"
          isOdata={true}
        />
      </div>
    );
  };

  downloadFile = () => {
    const { downloadResources, selectedDownloadResource } = this.state.resourceState;
    const props = {
      onChange: (event: CustomEvent) => {
        this.handleExportChange(event.detail); // here is where we are setting the selected resource
      },
      label: t('export_format'),
      className: 'forge-select export-formats',
      selectedIndex: selectedDownloadResource
        ? downloadResources.findIndex((opt: ResourceType) => opt.url === selectedDownloadResource.url)
        : 0
    };

    return (
      <>
        <ForgeSelect data-testid="export-type-select" {...props}>
          {downloadResources.map((downloadResource: ResourceType, index) => {
            return (
              <ForgeOption
                value={downloadResource.url}
                key={`export-option-${index}`}
                secondaryLabel={downloadResource.value === 'xlsx' ? t('xlsx_secondary_label') : ''}
              >
                {downloadResource.label}
              </ForgeOption>
            );
          })}
        </ForgeSelect>
        {this.filteringRadioButtons()}
      </>
    );
  };

  handleExportChange = (url: string) => {
    this.setState((prevState) => ({
      resourceState: {
        ...prevState.resourceState,
        selectedDownloadResource: prevState.resourceState.downloadResources.find((v) => v.url === url)
      }
    }));
  };

  handleSelectedUrlChange = (resource: ResourceType) => {
    this.setState((prevState) => ({
      resourceState: {
        ...prevState.resourceState,
        selectedApiResource: resource
      }
    }));
  };

  handleOdataVersionChange = (resource: ResourceType) => {
    this.setState((prevState) => ({
      resourceState: {
        ...prevState.resourceState,
        selectedOdataResource: resource
      }
    }));
  };

  downloadFileRequest = (downloadResource: ResourceType) => {
    const save: HTMLAnchorElement = document.createElement('a');

    fetch(downloadResource.url, {
      method: 'GET',
      credentials: 'same-origin'
    })
      .then((r) => checkStatus(r, `Error exporting data in format ${downloadResource.value}`))
      .then((response) => {
        // gets the filename from Content-Disposition (which is not always there)
        // when the filename is not provided we typically open the file in browser
        const contentHeaders = response.headers.get('Content-Disposition');
        if (contentHeaders) {
          const filename = contentDisposition.parse(contentHeaders)?.parameters?.filename;
          // Browser will assign a guid for name if filename is missing (shouldn't happen but possible)
          save.download = filename || '';
        } else if (downloadResource.value === 'rdf') {
          // rdf is a special case where we don't get a name for it
          // from the server and its not just opened in browser
          save.download = 'rows.rdf';
        }
        return response.blob();
      })
      .then((blob) => {
        const contentType = getMimeType(downloadResource.value);
        const url = window.URL.createObjectURL(new Blob([blob], { type: contentType }));
        save.target = '_blank';
        save.rel = 'noreferrer';
        save.setAttribute('style', 'display: none');
        document.body.appendChild(save);
        save.href = url;
        save.click();
        // delay to avoid race condition where browser could not load URL
        // before it was revoked
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 200);
        hideToastById(toastHideId);
        this.props.onDismiss();
      })
      .catch((error) => {
        console.error('Error attempting export request', error);
        showToastNow({
          type: ToastType.FORGE_ERROR,
          content: t('export_error'),
          timeout: Infinity,
          hideId: toastHideId
        });
      });
  };

  shouldShowFilteredDownload() {
    const { queryStringClause } = this.props;
    return isObject(queryStringClause)
      ? !isEmpty(queryStringClause.whereClause)
      : !isEmpty(queryStringClause);
  }

  shouldRenderOdataOption() {
    const isStoryteller = !isEmpty(window.STORY_DATA);
    const enableOdataEndpoint = FeatureFlags.valueOrDefault('enable_endpoints_in_stories') !== 'none';
    return isStoryteller && enableOdataEndpoint;
  }

  shouldRenderApiOption() {
    const isStoryteller = !isEmpty(window.STORY_DATA);
    const enableApiEndpoint = FeatureFlags.valueOrDefault('enable_endpoints_in_stories') === 'odata_api';
    return !isStoryteller || enableApiEndpoint;
  }

  shouldRenderExportOptions() {
    const isStoryteller = !isEmpty(window.STORY_DATA);
    const enableOptions = FeatureFlags.valueOrDefault('enable_endpoints_in_stories') !== 'none';
    return !isStoryteller || enableOptions;
  }

  render() {
    const { toggleOption } = this.state;
    const apiResourceUrl = this.state.resourceState.selectedApiResource.url;
    const odataResourceUrl = this.state.resourceState.selectedOdataResource.url;

    const dialogAttributes = new Map([
      ['aria-labelledby', 'export-dialog-title'],
      ['aria-describedby', 'export-dialog-body']
    ]);

    return (
      <ForgeDialog
        onDismiss={this.props.onDismiss}
        open={true}
        options={{ backdropClose: false, dialogAttributes }}
      >
        <div className="export-dataset-dialog">
          <ForgeScaffold>
            <div slot="header">
              <ForgeToolbar>
                <h1
                  slot="start"
                  id="export-dialog-title"
                  className="forge-typography--title forge-header-title"
                >
                  {t('export_dataset')}
                </h1>
                <ForgeIconButton slot="end">
                  <button data-testid="export-modal-x" className="tyler-icons" onClick={this.props.onDismiss}>
                    <ForgeIcon name="close" />
                  </button>
                </ForgeIconButton>
              </ForgeToolbar>
            </div>

            <div slot="body" className="export-dialog-container">
              <div>
                <p id="export-dialog-body" className="forge-typography--body2 body-text">
                  {this.props.bodyText}
                </p>
              </div>
              <div>
                {this.shouldRenderExportOptions() ? (
                  <ForgeButtonToggleGroup
                    id="export-button-group"
                    data-testid="export-toggle-buttons"
                    className="button-toggle"
                    mandatory={true}
                    value={toggleOption}
                    on-forge-button-toggle-group-change={(event: CustomEvent) =>
                      this.handleToggleChange(event.detail)
                    }
                  >
                    <ForgeButtonToggle
                      data-testid="export-toggle-download"
                      value={TOGGLE_OPTIONS.DOWNLOAD_FILE}
                      aria-label={t('download_file')}
                    >
                      {t('download_file')}
                    </ForgeButtonToggle>
                    {this.shouldRenderApiOption() ? (
                      <ForgeButtonToggle
                        data-testid="export-toggle-api"
                        value={TOGGLE_OPTIONS.API_ENDPOINT}
                        aria-label={t('api_endpoint')}
                      >
                        {t('api_endpoint')}
                      </ForgeButtonToggle>
                    ) : null}
                    {this.shouldRenderOdataOption() ? (
                      <ForgeButtonToggle
                        data-testid="export-toggle-odata"
                        value={TOGGLE_OPTIONS.ODATA_ENDPOINT}
                        aria-label={t('odata_endpoint')}
                      >
                        {t('odata_endpoint')}
                      </ForgeButtonToggle>
                    ) : null}
                  </ForgeButtonToggleGroup>
                ) : null}
              </div>
              <div className="export-formats">
                {toggleOption === TOGGLE_OPTIONS.ODATA_ENDPOINT && this._OdataEndpoint()}
                {toggleOption === TOGGLE_OPTIONS.API_ENDPOINT && this.apiEndpoint()}
                {toggleOption === TOGGLE_OPTIONS.DOWNLOAD_FILE && this.downloadFile()}
              </div>
            </div>

            <div slot="footer" className="footer">
              <ForgeToolbar inverted={true}>
                <ForgeButton slot="end" type="outlined">
                  <button data-testid="export-modal-cancel" onClick={this.props.onDismiss}>
                    {t('cancel')}
                  </button>
                </ForgeButton>

                {this.state.toggleOption === TOGGLE_OPTIONS.DOWNLOAD_FILE && (
                  <ForgeButton slot="end" type="raised">
                    <button
                      data-testid="export-download-button"
                      onClick={this.onDownload}
                      disabled={!this.state.resourceState.selectedDownloadResource?.url}
                    >
                      {t('download')}
                    </button>
                  </ForgeButton>
                )}
                {this.state.toggleOption === TOGGLE_OPTIONS.API_ENDPOINT && (
                  <CopyToClipboard text={apiResourceUrl}>
                    <ForgeButton slot="end" type="raised">
                      <button
                        data-testid="api-copy-button"
                        onClick={() => {
                          this.onCopyApiToast();
                          this.props.onDismiss();
                        }}
                        disabled={!apiResourceUrl}
                      >
                        {t('copy_clipboard')}
                      </button>
                    </ForgeButton>
                  </CopyToClipboard>
                )}
                {this.state.toggleOption === TOGGLE_OPTIONS.ODATA_ENDPOINT && (
                  <CopyToClipboard text={odataResourceUrl}>
                    <ForgeButton slot="end" type="raised">
                      <button
                        data-testid="odata-copy-button"
                        onClick={() => {
                          this.onCopyOdataToast();
                          this.props.onDismiss();
                        }}
                        disabled={!odataResourceUrl}
                      >
                        {t('copy_clipboard')}
                      </button>
                    </ForgeButton>
                  </CopyToClipboard>
                )}
              </ForgeToolbar>
            </div>
          </ForgeScaffold>
        </div>
      </ForgeDialog>
    );
  }
}
