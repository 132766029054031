import _ from 'lodash';

import { Vif } from 'common/visualizations/vif';

import * as commonVifDecorator from './commonVifDecorator';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './li... Remove this comment to see the full error message
import * as lineMapVifDecorator from './lineMapVifDecorator';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './po... Remove this comment to see the full error message
import * as pointMapVifDecorator from './pointMapVifDecorator';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './re... Remove this comment to see the full error message
import * as regionMapVifDecorator from './regionMapVifDecorator';
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module './sh... Remove this comment to see the full error message
import * as shapeMapVifDecorator from './shapeMapVifDecorator';
import { View } from 'common/types/view';

export function getDecoratedVif(vif: Vif): DecoratedVif {
  return _.merge(
    {},
    commonVifDecorator,
    lineMapVifDecorator,
    pointMapVifDecorator,
    regionMapVifDecorator,
    shapeMapVifDecorator,
    {
      cloneWithSingleSeries: function (seriesIndex: number) {
        const clonedVif = _.cloneDeep(this);
        const series = _.get(clonedVif, `series[${seriesIndex}]`);

        clonedVif.series = [series];

        return getDecoratedVif(clonedVif);
      }
    },
    vif
  );
}

type VifDecorations = Vif & typeof commonVifDecorator;
export interface DecoratedVif extends VifDecorations {
  cloneWithSingleSeries: (seriesIndex: number) => DecoratedVif;
  /* TODO: These are defined by the vif decorators included above. They should be moved to those files when they're converted to Typescript */
  getRangeBucketType: () => 'equalInterval' | 'jenks';
  isRegionMap: () => boolean;
  getShapeGeometryColumn: (view: View) => string;
}
