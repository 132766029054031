// Vendor Imports
import d3 from 'd3';
import $ from 'jquery';
import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

// Project Imports
import 'common/js_utils'; // for side effect of CustomEvent polyfill

import 'common/visualizations/jquery';
import { ColumnFormat, ViewColumn } from 'common/types/viewColumn';
import { Vif, Series, SeriesType, DataRow, LineStyle } from 'common/visualizations/vif';
import {
  BaseVisualization as BaseVizType,
  CalculateLeftOrRightMarginOptions,
  DataToRender,
  GenerateYAxisOptions,
  GetAnnotationFlyoutContentOptions,
  GetColumnFormattedValueTextOptions,
  GetDimensionColumnFormattedValueTextOptions,
  GetFlyoutContentOptions,
  GetGroupFlyoutContentOptions,
  GetMeasureColumnFormattedValueTextOptions,
  GetPercentFormattedValueTextOptions,
  GetRowValueExtentOptions,
  GetRowValueSummedExtentOptions,
  GetStackedPositionsOptions,
  GetValueLabelYOptions,
  IsPercentLabelInsideOptions,
  IsValueLabelInsideOptions,
  Position,
  RotateDimensionLabelsOptions,
  ShowReferenceLineFlyoutOptions,
  ViewPortRectangle,
  VisualizationOptions,
  WrapDimensionLabelsOptions
} from './types';
import { VisualizationMeasure } from 'common/visualizations/helpers/types';
import {
  formatValueHTML,
  formatValuePlainText,
  formatValuePlainTextWithFormatInfo
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '../.... Remove this comment to see the full error message
} from '../../helpers/ColumnFormattingHelpers';
import { calculateTextSize } from '../../helpers/SvgHelpers';
import * as VifHelpers from 'common/visualizations/helpers/VifHelpers';
import { migrateVif } from 'common/visualizations/helpers/migrateVif';
import I18n from 'common/i18n';
import assertInstanceOf from 'common/assertions/assertInstanceOf';
import { getPrimarySeriesIndex } from 'common/visualizations/helpers/MapHelper';
import { isPrintTableEnabled } from 'common/visualizations/helpers/AgGridPrintHelper';

import {
  getAxisLabels,
  getCurrentDrilldownColumnName,
  getDimensionLabelRotationAngle,
  getMeasureAxisMaxValue,
  getMeasureAxisMinValue,
  getNonFlyoutSeries,
  getShowNullsAsFalse,
  getShowValueLabelsAsPercent,
  getWrapDimensionLabels,
  isBarVisualization,
  isMapVisualization,
  isTableVisualization,
  isGrouping,
  isGroupingOrHasMultipleNonFlyoutSeries,
  logScale,
  newVizCardLayoutEnabled,
  shouldRenderDrillDown
} from 'common/visualizations/helpers/VifSelectors';
import { isMobileOrTablet } from 'common/visualizations/helpers/MediaQueryHelper';
import DataTypeFormatter from 'common/DataTypeFormatter';
import VizMessageError from 'common/components/VizMessageError';
import { getSmallestNumber } from 'common/js_utils/formatNumber';

// Constants
import {
  AXIS_LABEL_COLOR,
  AXIS_LABEL_FONT_SIZE,
  AXIS_LABEL_TEXT_MARGIN,
  D3_TICK_PADDING,
  D3_TICK_SIZE,
  DEFAULT_HIGHLIGHT_COLOR,
  DIMENSION_LABELS_FONT_SIZE,
  DIMENSION_LABELS_LINE_HEIGHT_EMS,
  DIMENSION_LABELS_Y_TRANSLATION,
  FONT_STACK,
  GLYPH_SPACE_HEIGHT,
  LABEL_PADDING_WIDTH,
  LABEL_PERCENT_WIDTH,
  MEASURE_VALUE_TEXT_Y_SHIFT,
  MINIMUM_LABEL_WIDTH,
  MINIMUM_Y_AXIS_TICK_DISTANCE,
  VALUE_LABEL_FONT_SIZE,
  VALUE_LABEL_MARGIN
} from '../SvgConstants';

import { CHECKBOX_COLUMN_TYPE } from 'common/authoring_workflow/constants';

import * as ActionsToolbarContainer from './ActionsToolbar';
import * as DrilldownContainer from './DrilldownContainer';
import * as FilterBarContainer from './FilterBarContainer';
import * as ExpandCollapseContainer from './ExpandCollapseContainer';
import * as InfoContainer from './InfoContainer';
import * as LegendBarContainer from './LegendBarContainer';
import * as LegendPaneContainer from './LegendPaneContainer';
import * as MetadataContainer from './MetadataContainer';
import * as PanningNoticeContainer from './PanningNoticeContainer';
import * as ViewTabsContainer from './ViewTabsContainer';

const DEFAULT_TYPE_VARIANTS = {
  columnChart: 'column', // others: 'bar'
  timelineChart: 'area' // others: 'line'
};

/**
 * @param {JQuery} $element Element in which the visualization is to be rendered.
 * @param {Vif} vif See vif.ts and Vif.MD
 * @param {VisualizationOptions} options see BaseVisualization/types.ts
 */
function BaseVisualization(this: BaseVizType, $element: JQuery, vif: Vif, options?: VisualizationOptions) {
  // These constants need to be defined inside the function
  // because I18n does not get hydrated in embeds until after
  // they were getting set. By moving them into the function,
  // I18n has time to get hydrated. Relates to EN-18831
  const noValueLabel = I18n.t('shared.visualizations.charts.common.no_value');
  const otherLabel = I18n.t('shared.visualizations.charts.common.other_category');
  const falseLabel = I18n.t('shared.visualizations.charts.common.false_value');

  // TODO: Seems risky to totally overhaul the this binding method for BaseVisualization right now.
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const self = this;

  let currentVif = vif;
  let currentSummaryTableVif: Vif;
  self.originalVif = vif;
  let currentOptions = options;
  let currentColumns: ViewColumn[];
  let currentComputedColumns: ViewColumn[];
  // CurrentShouldShowTable references summary table for charts and maps
  let currentShouldShowTable = false;
  let $summaryTableContainer: JQuery;
  const printTableEnabled = isPrintTableEnabled();

  self.isFilterBarShown = false;

  // NOTE: Initialization occurs at the bottom of the file!
  /**
   * Public methods
   */

  this.getVif = () => currentVif;
  this.getColumns = () => currentColumns;
  this.getComputedColumns = () => currentComputedColumns;
  this.getOptions = () => currentOptions;
  this.shouldShowTable = () => currentShouldShowTable;

  this.getSeriesIndex = () => {
    let seriesIndex = 0;
    const series = _.get(this.getVif(), 'series', []);
    if (series.length > 1) {
      seriesIndex = getPrimarySeriesIndex(this.getVif());
    }

    return seriesIndex;
  };

  this.updateVif = (vifToRender: Vif) => {
    currentVif = _.merge(VifHelpers.getDefaultVif(), migrateVif(vifToRender));

    MetadataContainer.onUpdateVif(self.$container, self.getVif(), self);
    if (!printTableEnabled) {
      FilterBarContainer.renderFilterBar(self);
      InfoContainer.onUpdateVif(self);
    }

    ExpandCollapseContainer.renderExpandCollapse(self.$container, self.getVif(), self.emitVifEvent);
    if (newVizCardLayoutEnabled(self.getVif())) {
      ActionsToolbarContainer.onUpdateVif(self);
    }
    self.hidePanningNotice();

    // NewGLMaps needs a different visualization style and we are adding a class to override the styles
    self.$container.toggleClass('visualization-for-new-gl-maps', isMapVisualization(self.getVif()));
  };

  this.updateSummaryTableVif = (tableVif: Vif) => {
    currentSummaryTableVif = _.merge(VifHelpers.getDefaultVif(), migrateVif(tableVif));

    if (!$summaryTableContainer) {
      // If the summary table has never been created, instantiate a new one with the new vif
      $summaryTableContainer = self.$element
        .find('.socrata-visualization-summary-table-container')
        .socrataTable(currentSummaryTableVif, { isSummaryTable: true });
    } else {
      // If the summary table already exists, then trigger a vif update
      const renderVifEvent: { originalEvent: { detail: Vif } } & JQuery.Event = new $.Event(
        'SOCRATA_VISUALIZATION_RENDER_VIF'
      );
      renderVifEvent.originalEvent = {
        detail: currentSummaryTableVif
      };
      $summaryTableContainer.triggerHandler(renderVifEvent);
    }
  };

  this.updateColumns = (columns: ViewColumn[] | null, computedColumns?: ViewColumn[]) => {
    currentColumns = columns ?? [];
    currentComputedColumns = computedColumns ?? [];
    FilterBarContainer.renderFilterBar(self);
    ExpandCollapseContainer.renderExpandCollapse(self.$container, self.getVif(), self.emitVifEvent);
  };

  this.updateOptions = (_options?: VisualizationOptions) => {
    currentOptions = _options;
    FilterBarContainer.renderFilterBar(self);
    ExpandCollapseContainer.renderExpandCollapse(self.$container, self.getVif(), self.emitVifEvent);
  };

  /**
   * Used to switch between the summary table and chart tabs.
   */
  this.updateShouldShowTable = (shouldShowTable: boolean) => {
    if (currentShouldShowTable === shouldShowTable) {
      return;
    }

    currentShouldShowTable = shouldShowTable;

    // Update pieces of the UI that read shouldShowTable
    ViewTabsContainer.renderReact(
      self.$element,
      isMapVisualization(self.getVif()),
      () => self.updateShouldShowTable(true),
      () => self.updateShouldShowTable(false),
      currentShouldShowTable,
      self.visualizationId,
      newVizCardLayoutEnabled(self.getVif())
    );

    // toggle to the correct tab
    ViewTabsContainer.toggleTab(
      self.$container,
      'visualization',
      self.visualizationId,
      self.shouldShowTable()
    );
    ViewTabsContainer.toggleTab(
      self.$container,
      'summary-table',
      self.visualizationId,
      !self.shouldShowTable()
    );

    // toggle maps
    const $mapContainer = self.$container.find('.unified-map-instance');
    $mapContainer.toggleClass('hidden', self.shouldShowTable());

    // When the tab is toggled to the summary table we need to hide parts of the UI
    const $drilldownContainer = self.$container.find('.socrata-visualization-drilldown-container');
    $drilldownContainer.toggleClass('hidden', self.shouldShowTable());

    const $legendPaneContainer = self.$container.find('.socrata-visualization-legend-pane-container');
    $legendPaneContainer.toggleClass('hidden', self.shouldShowTable());

    const $legendBarContainer = self.$container.find('.socrata-visualization-legend-bar-container');
    $legendBarContainer.toggleClass('hidden', self.shouldShowTable());

    if (self.panningNoticeVisible) {
      if (self.shouldShowTable()) {
        PanningNoticeContainer.hide(self.$container);
      } else {
        PanningNoticeContainer.show(self.$container);
      }
    }

    self.$container.toggleClass('is-showing-summary-table', self.shouldShowTable());

    // Trigger a resize on the table or chart
    if (self.shouldShowTable()) {
      $summaryTableContainer.find('.socrata-visualization').trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    } else {
      self.$container.trigger('SOCRATA_VISUALIZATION_INVALIDATE_SIZE');
    }
  };

  this.emitVifEvent = (newVif: Vif) => {
    const handleVifUpdatesInternally = _.get(this.getOptions(), 'drilldown.handleVifUpdatesInternally', true);
    if (handleVifUpdatesInternally) {
      this.emitEvent('SOCRATA_VISUALIZATION_RENDER_VIF', newVif);
    } else {
      this.emitEvent('SOCRATA_VISUALIZATION_VIF_UPDATED', newVif);
    }
  };

  let topAxisLabelElement: d3.Selection<SVGGraphicsElement> | null = null;
  let rightAxisLabelElement: d3.Selection<SVGGraphicsElement> | null = null;
  let bottomAxisLabelElement: d3.Selection<SVGGraphicsElement> | null = null;
  let leftAxisLabelElement: d3.Selection<SVGGraphicsElement> | null = null;

  /**
   * Render axis labels positioned according to given bounding box
   *
   * @param containerSvg D3 wrapped container svg element
   * @param {{x: Number, y: Number, width: Number, height: Number}} viewportRect
   */
  this.renderAxisLabels = (
    containerSvg: d3.Selection<SVGGraphicsElement>,
    viewportRect: ViewPortRectangle
  ) => {
    const axisLabels = getAxisLabels(self.getVif());
    const axisLabelTextSizes = _.mapValues(
      axisLabels,
      (axisLabelText: string | boolean, key): { height: number; text: string; width: number } => {
        if (axisLabelText === false) {
          return {
            height: 0,
            text: '',
            width: 0
          };
        }

        let truncated = false;
        let text: string = axisLabelText as string;
        const { height, width: w } = calculateTextSize(FONT_STACK, AXIS_LABEL_FONT_SIZE, text);
        let width = w;

        const maximumWidth = key === 'left' || key === 'right' ? viewportRect.height : viewportRect.width;

        while (width > maximumWidth && text.length > 0) {
          text = text.slice(0, -1);
          width = calculateTextSize(FONT_STACK, AXIS_LABEL_FONT_SIZE, text + '…').width;
          truncated = true;
        }

        return {
          height,
          text: truncated ? text + '…' : text,
          width
        };
      }
    );

    const xAxisBox = containerSvg.select('.x.axis').node();
    const yAxisBox = containerSvg.select('.y.axis').node();
    const secondaryYAxisBox = containerSvg.select('.y.secondaryAxis').node();

    const xAxisHeight = xAxisBox ? (xAxisBox as SVGGraphicsElement).getBBox().height : 0;
    const yAxisWidth = yAxisBox ? (yAxisBox as SVGGraphicsElement).getBBox().width : 0;
    const secondaryYAxisWidth = secondaryYAxisBox
      ? (secondaryYAxisBox as SVGGraphicsElement).getBBox().width
      : 0;

    const chartMidY = viewportRect.y + viewportRect.height / 2.0;
    const chartMidX = viewportRect.x + (viewportRect.width - yAxisWidth) / 2.0;
    const chartMaxX = viewportRect.x + viewportRect.width;
    const chartMaxY = viewportRect.y + viewportRect.height + xAxisHeight;

    // Render/Remove left axis title
    leftAxisLabelElement = updateAxisLabel(
      containerSvg,
      leftAxisLabelElement,
      axisLabelTextSizes.left.text,
      'socrata-visualization-left-axis-title',
      (element: d3.Selection<SVGGraphicsElement>) => {
        const left = axisLabelTextSizes.left.height + AXIS_LABEL_TEXT_MARGIN;
        element.attr('transform', `translate(${left}, ${chartMidY}) rotate(-90)`);
      }
    );

    // Render/Remove bottom axis title
    bottomAxisLabelElement = updateAxisLabel(
      containerSvg,
      bottomAxisLabelElement,
      axisLabelTextSizes.bottom.text,
      'socrata-visualization-bottom-axis-title',
      (element: d3.Selection<SVGGraphicsElement>) => {
        const top = chartMaxY + 2 * AXIS_LABEL_TEXT_MARGIN;
        element.attr('transform', `translate(${chartMidX}, ${top})`);
      }
    );

    // Render/Remove top axis title
    topAxisLabelElement = updateAxisLabel(
      containerSvg,
      topAxisLabelElement,
      axisLabelTextSizes.top.text,
      'socrata-visualization-top-axis-title',
      (element: d3.Selection<SVGGraphicsElement>) => {
        const top = axisLabelTextSizes.top.height + AXIS_LABEL_TEXT_MARGIN;
        element.attr('transform', `translate(${chartMidX}, ${top})`);
      }
    );

    // Render/Remove right axis title
    rightAxisLabelElement = updateAxisLabel(
      containerSvg,
      rightAxisLabelElement,
      axisLabelTextSizes.right.text,
      'socrata-visualization-right-axis-title',
      (element: d3.Selection<SVGGraphicsElement>) => {
        const left = chartMaxX + secondaryYAxisWidth + AXIS_LABEL_TEXT_MARGIN;
        element.attr('transform', `translate(${left}, ${chartMidY}) rotate(90)`);
      }
    );
  };

  this.getFirstSeriesOfType = (types: SeriesType) => {
    const allSeries = _.get(self.getVif(), 'series', []);
    return _.find(allSeries, (series) => _.includes(types, series.type));
  };

  /** Calculates the proper left or right margin for the chart using a simulated Y axis. */
  this.calculateLeftOrRightMargin = ({
    dataToRender,
    height,
    isSecondaryAxis,
    series
  }: CalculateLeftOrRightMarginOptions) => {
    if (_.isNil(dataToRender)) {
      return MINIMUM_LABEL_WIDTH + LABEL_PADDING_WIDTH;
    }

    const values = _.flatMap(dataToRender.rows, (row) => _.tail(row).map(_.toNumber)).concat(0);

    // NOTE: This _does_ take custom axis scaling into account (in case there's a very long
    // decimal in the actual D3 calculated scale), but _does not_ consider number formatting
    // (like adding a percent sign or padding out the decimal places). Not fixing that now
    // because it doesn't seem to be causing any issues, but is a potential future bug :(
    const minValue = getMeasureAxisMinValue(self.getVif()) || _.min(values);
    const maxValue = getMeasureAxisMaxValue(self.getVif()) || _.max(values);

    // Generate a Y axis on a fake chart using our real axis generator.
    const testSvg = d3.select('body').append('svg');
    const testScale = self.generateYScale(minValue!, maxValue!, height);
    testSvg
      .append('g')
      .attr('class', 'y axis')
      .call(
        self.generateYAxis({
          dataToRender,
          height,
          isSecondaryAxis,
          scale: testScale,
          series
        })
      );

    // Get the widths of all generated tick labels.
    const testLabelWidths = _.map<any, number>(
      testSvg.selectAll('.tick text')[0],
      (el: SVGTextContentElement): number => (typeof el?.getBBox === 'function' ? el.getBBox().width : 0)
    );

    // Clean up the fake chart.
    testSvg.remove();

    // Return the largest label width (minimum 35px), plus a bit of padding.
    // For reference, the original chart width was hard-coded to 50px.
    const padding = _.get(series, 'dataSource.measure.columnFormat.format.asPercent', false)
      ? LABEL_PADDING_WIDTH + LABEL_PERCENT_WIDTH
      : LABEL_PADDING_WIDTH;

    return _.chain(testLabelWidths).concat(MINIMUM_LABEL_WIDTH).max().ceil().value() + padding;
  };

  this.generateYAxis = ({ dataToRender, height, isSecondaryAxis, scale, series }: GenerateYAxisOptions) => {
    const isOneHundredPercentStacked = _.get(series, 'stacked.oneHundredPercent', false);
    let formatter;

    if (isOneHundredPercentStacked) {
      formatter = d3.format('.0%'); // rounds to a whole number percentage
    } else {
      const columnName = _.get(series, 'dataSource.measure.columnName');
      const axisPrecision = _.get(
        self.getVif(),
        isSecondaryAxis ? 'configuration.secondaryMeasureAxisPrecision' : 'configuration.measureAxisPrecision'
      );

      const formatInfo = _.cloneDeep(_.get(dataToRender, `columnFormats.${columnName}`, {}));
      let forceHumane = true; // Y-axis ticks default to displaying nicely formatted values (forceHumane == true)

      if (!_.isNil(axisPrecision)) {
        _.set(formatInfo, 'format.precision', axisPrecision);
        forceHumane = false;
      }

      // Unless absolute values of all data points are less than the smallest number given default precision,
      // set retainSmallDecimals options to false to avoid possible rounding errors near 0 value.
      // Use axis' domain range to determine this.
      const smallestNumber = getSmallestNumber();
      const [minYValue, maxYValue] = scale.domain();
      const absMinYValue = Math.abs(minYValue);
      const absMaxYValue = Math.abs(maxYValue);
      const retainSmallDecimals = absMinYValue <= smallestNumber && absMaxYValue <= smallestNumber;

      formatter = (value: number) =>
        axisPrecision === 0 && !_.isInteger(value)
          ? null
          : formatValuePlainTextWithFormatInfo({
              dataToRender,
              forceHumane,
              formatInfo,
              value,
              retainSmallDecimals
            });
    }

    const yAxis = d3.svg
      .axis()
      .scale(scale)
      .orient(isSecondaryAxis ? 'right' : 'left')
      .tickFormat(formatter);

    if (logScale(self.getVif())) {
      const yAxisTicks = scale.ticks().filter((tick: number) => Number.isInteger(Math.log10(tick)));
      yAxis.tickValues(yAxisTicks);
    }

    const ticksToFitHeight = Math.ceil(height / MINIMUM_Y_AXIS_TICK_DISTANCE);
    const isCount = _.get(series, 'dataSource.measure.aggregationFunction') === 'count';

    if (isCount && !logScale(self.getVif())) {
      // If the number of possible values is small, limit number of ticks to force integer values.
      const [minYValue, maxYValue] = scale.domain();
      const span = maxYValue - minYValue;

      if (span < 10) {
        const ticks = d3.range(minYValue, maxYValue + 1, 1);

        if (ticks.length <= ticksToFitHeight) {
          yAxis.tickValues(ticks);
        } else {
          yAxis.ticks(ticksToFitHeight);
        }
      } else {
        yAxis.ticks(ticksToFitHeight);
      }
    } else {
      yAxis.ticks(ticksToFitHeight);
    }

    return yAxis;
  };

  function createAxisLabelGroup(containerSvg: d3.Selection<SVGGraphicsElement>, className: string) {
    const group = containerSvg.append('g');

    group
      .append('text')
      .attr('class', className)
      .attr('text-anchor', 'middle')
      .attr('font-family', FONT_STACK)
      .attr('font-size', AXIS_LABEL_FONT_SIZE)
      .attr('fill', AXIS_LABEL_COLOR);

    group
      // Not sure why this is weird, but it's been working this long.
      // @ts-expect-error TS(2345) FIXME: Argument of type '(event: TriggeredEvent<any, any,... Remove this comment to see the full error message
      .on('mouseover', self.showFlyout)
      .on('mouseout', self.hideFlyout);

    return group;
  }

  function updateAxisLabel(
    parentElement: d3.Selection<SVGGraphicsElement>,
    axisLabelElement: d3.Selection<SVGGraphicsElement> | null,
    title: string,
    className: string,
    nodeUpdateFn: (el: d3.Selection<SVGGraphicsElement>) => void
  ) {
    let element = axisLabelElement;

    if (title) {
      if (element === null) {
        element = createAxisLabelGroup(parentElement, className);
      }

      nodeUpdateFn(element);
      element.select('text').text(title);

      if (!parentElement.node().contains(element.node())) {
        parentElement.node().appendChild(element.node());
      }
    } else if (element && element.node().parentElement) {
      element.remove();
    }

    return element;
  }

  this.renderErrorWithHTML = (htmlString: string) => {
    const $message = self.$container.find('.socrata-visualization-error-message');

    $message.empty().append(htmlString);

    self.$container.removeClass('socrata-visualization-busy').addClass('socrata-visualization-error');
  };

  this.renderNoDataError = () => {
    this.renderVizError(I18n.t('shared.no_rows_overlay.body'), 'noDataError');
  };

  this.renderGenericError = (messages, type) => {
    this.renderVizError(messages, type);
  };

  this.renderVizError = (errorMessage: string, type: string) => {
    const svgHolder = self.$container.find('.svg-holder');
    if (svgHolder != null) {
      svgHolder.remove();
    }

    const newDiv = document.createElement('div');
    newDiv.classList.add('svg-holder');
    ReactDOM.render(React.createElement(VizMessageError, { type, errorMessage }), newDiv);

    const $message = self.$container.find('.socrata-visualization-error-message');
    $message.empty();
    const $errorDiv = self.$container.find('.socrata-visualization-error-container');
    $errorDiv.css('background-color', '#ffffff');
    $errorDiv.append(newDiv);

    self.$container.removeClass('socrata-visualization-busy').addClass('socrata-visualization-error');
  };

  this.renderError = (messages?: string | string[]) => {
    const svgHolder = self.$container.find('.svg-holder');
    if (svgHolder != null) {
      svgHolder.remove();

      const $errorDiv = self.$container.find('.socrata-visualization-error-container');
      $errorDiv.css('background-color', '#faf0f0');
    }
    const $message = self.$container.find('.socrata-visualization-error-message');
    if (!messages || _.isString(messages) || messages.length === 1) {
      $message.text((messages || 'Error') as string);
    } else {
      $message
        .empty()
        .append(
          $('<h1>').text(I18n.t('shared.visualizations.charts.common.validation.errors.multiple_errors'))
        )
        .append(
          $('<ul>').append(
            messages.map((text) => {
              return $('<li>').text(text);
            })
          )
        );
    }

    self.$container.removeClass('socrata-visualization-busy').addClass('socrata-visualization-error');
  };

  this.clearError = () => {
    self.$container.find('.socrata-visualization-error-message').text('');
    self.$container.removeClass('socrata-visualization-error');
  };

  this.showBusyIndicator = () => {
    self.$container.addClass('socrata-visualization-busy');
  };

  this.hideBusyIndicator = () => {
    self.$container.removeClass('socrata-visualization-busy');
  };

  this.rotateDimensionLabels = (
    selection: d3.Selection<SVGElement>,
    { dataToRender, maxHeight, maxWidth }: RotateDimensionLabelsOptions
  ) => {
    const angle = getDimensionLabelRotationAngle(self.getVif());

    if (getWrapDimensionLabels(self.getVif())) {
      self.wrapDimensionLabels({
        angle,
        dataToRender,
        maxHeight,
        maxWidth,
        selection
      });
    } else {
      truncateDimensionLabels({
        angle,
        maxHeight,
        maxWidth,
        selection
      });
    }

    // Apply translation after wrapping and truncation to get the correct
    // width of each dimension label text element.
    selection.call(applyTranslationAndRotationTransform, angle);
  };

  this.wrapDimensionLabels = ({
    angle,
    dataToRender,
    maxHeight,
    maxWidth,
    selection
  }: WrapDimensionLabelsOptions) => {
    const angleInRadians = (Math.abs(angle) * Math.PI) / 180;
    const dimensionIndex = 0;
    const availableWidth = getDimensionLabelWidthForChartType({
      angle,
      height: maxHeight,
      width: maxWidth
    });

    const columnName =
      getCurrentDrilldownColumnName(this.getVif()) ||
      _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');

    // Break dimension labels on spaces, using a tspan for each line.
    selection.each(function (d) {
      const textElement = d3.select(this);
      const dy = parseFloat(textElement.attr('dy'));
      const lineHeightPx = DIMENSION_LABELS_LINE_HEIGHT_EMS * DIMENSION_LABELS_FONT_SIZE;
      const x = textElement.attr('x');
      const y = textElement.attr('y');
      const value = _.isArray(d) ? d[dimensionIndex] : d;
      const dimensionLabel = self.getColumnFormattedValueText({
        columnName,
        dataToRender,
        value
      });

      const words = dimensionLabel.split(/\s+/).reverse();

      // Horizontal labels are constrained by maxHeight, all other angles by maxWidth
      const constrainingPx = angle === 0 ? maxHeight : maxWidth;

      let line: string[] = [];
      let lineNumber = 0;
      let word = words.pop();
      let tspan = textElement
        // Not sure what the right thing is here, but it's worked so far.
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        .text(null)
        .append('tspan')
        .attr('x', x)
        .attr('y', y)
        .attr('dy', dy + 'em')
        .attr('maxWidth', availableWidth);

      while (!_.isUndefined(word)) {
        line.push(word);
        tspan.text(line.join(' '));

        const { width } = calculateTextSize(FONT_STACK, DIMENSION_LABELS_FONT_SIZE, tspan.text());

        if (width > availableWidth) {
          line.pop();

          if (line.length > 0) {
            lineNumber++;

            // Check to see if the height of the wrapped words is greater
            // than the constraining dimension (which is maxWidth for column,
            // timeline and combo charts, or maxHeight for bar charts).  If
            // it will be too tall, don't add anymore tspans, just set
            // ellipsis on the current tspan and return.
            const neededPx = (lineNumber + 1) * lineHeightPx;

            if (neededPx > constrainingPx) {
              tspan.text(tspan.text() + '…');
              return;
            }

            // Because the next line is shifted down, the available space
            // before truncation must be shortened depending on the angle.
            // Figure out the availableWidthForLine and set it as the
            // maxWidth attribute on the tspan, which the truncator will
            // use below.
            const dyPx = lineNumber * lineHeightPx;
            const dxPx = angle === 0 ? 0 : dyPx / Math.tan(angleInRadians);
            const availableWidthForLine = Math.max(Math.floor(availableWidth - dxPx), 0);

            tspan.text(line.join(' '));
            tspan = textElement
              .append('tspan')
              .attr('x', x)
              .attr('y', y)
              .attr('dy', lineNumber * DIMENSION_LABELS_LINE_HEIGHT_EMS + dy + 'em')
              .attr('maxWidth', availableWidthForLine)
              .text(word);
          }

          line = [word];
        }

        word = words.pop();
      }
    });

    // Truncate the individual tspans if necessary
    selection.selectAll('tspan').each(function () {
      const tspan = d3.select(this);
      const _maxWidth = parseInt(tspan.attr('maxWidth'), 10);
      const text = truncateText({
        fontFamily: FONT_STACK,
        fontSize: DIMENSION_LABELS_FONT_SIZE,
        maxWidth: _maxWidth,
        returnEllipsisForEmpty: Math.abs(angle) === 90,
        text: tspan.text()
      });

      tspan.text(text);
    });
  };

  interface TruncateDimensionLabelsOptions {
    angle: number;
    maxHeight: number;
    maxWidth: number;
    selection: d3.Selection<SVGElement>;
  }
  const truncateDimensionLabels = ({
    angle,
    maxHeight,
    maxWidth,
    selection
  }: TruncateDimensionLabelsOptions) => {
    const availableWidth = getDimensionLabelWidthForChartType({
      angle,
      height: maxHeight,
      width: maxWidth
    });

    selection.each(function () {
      const textElement = d3.select(this);
      const text = truncateText({
        fontFamily: FONT_STACK,
        fontSize: DIMENSION_LABELS_FONT_SIZE,
        maxWidth: availableWidth,
        text: textElement.text()
      });

      textElement.text(text);
    });
  };

  interface TruncateTextOptions {
    fontFamily: string;
    fontSize: string | number;
    maxWidth: number;
    returnEllipsisForEmpty?: boolean;
    text: string;
  }
  const truncateText = ({
    fontFamily,
    fontSize,
    maxWidth,
    returnEllipsisForEmpty,
    text
  }: TruncateTextOptions) => {
    let { width } = calculateTextSize(fontFamily, fontSize, text);
    let truncated = false;

    while (width > maxWidth && text.length > 0) {
      text = text.slice(0, -1);
      width = calculateTextSize(fontFamily, fontSize, text + '…').width;
      truncated = true;
    }

    if (text.length > 0) {
      return truncated ? text + '…' : text;
    } else if (returnEllipsisForEmpty) {
      return '…';
    } else {
      return '';
    }
  };

  const applyTranslationAndRotationTransform = (selection: d3.Selection<SVGElement>, angle: number) => {
    const anchor = getDimensionLabelTextAnchor(angle);

    // Rotate first so we can get the width of the rotated bounding rect as
    // it appears on screen.
    selection
      .attr('style', `text-anchor: ${anchor}`)
      .attr('transform', `rotate(${angle})`)
      .attr('transform-origin', 'top left');

    const angleInRadians = (Math.abs(angle) * Math.PI) / 180;

    selection.each(function () {
      const text = d3.select(this);
      const rect = (text.node() as Element).getBoundingClientRect();

      let xTranslation;
      let yTranslation;

      if (isBarVisualization(self.getVif())) {
        xTranslation = 0;
        yTranslation = -Math.ceil(rect.height / 2) + 10;
      } else {
        yTranslation = DIMENSION_LABELS_Y_TRANSLATION * Math.sin(angleInRadians);

        if (angle == 0) {
          xTranslation = 0;
        } else if (angle == 90) {
          xTranslation = Math.ceil(rect.width / 2) + 5;
        } else if (angle == -90) {
          xTranslation = -Math.ceil(rect.width / 2) - 5;
        } else if (angle > 0) {
          xTranslation = 11;
        } else if (angle < 0) {
          xTranslation = -11;
        }
      }

      // Now apply the translation (and rotation again since it's all on the
      // transform attribute).
      text.attr('transform', `translate(${xTranslation},${yTranslation}) rotate(${angle})`);
    });
  };

  interface GetDimensionLabelWidthOptions {
    angle: number;
    height: number;
    width: number;
  }
  const getDimensionLabelWidthForChartType = ({ angle, height, width }: GetDimensionLabelWidthOptions) => {
    const isBarChart = isBarVisualization(self.getVif());
    const availableHeight = isBarChart ? height : Math.max(height - D3_TICK_PADDING - D3_TICK_SIZE, 0);
    const availableWidth = isBarChart ? Math.max(width - D3_TICK_PADDING - D3_TICK_SIZE, 0) : width;

    return getDimensionLabelWidth({
      angle,
      height: availableHeight,
      width: availableWidth
    });
  };

  // Returns the hypotenuse - the maximum available length of the dimension
  // label for a given rotation angle and available height and width.
  const getDimensionLabelWidth = ({ angle, height, width }: GetDimensionLabelWidthOptions) => {
    const angleInRadians = (Math.abs(angle) * Math.PI) / 180;
    const availableWidth = angle == 0 ? width : height / Math.sin(angleInRadians); // sohcahtoa!

    return Math.max(Math.floor(availableWidth), 0);
  };

  const getDimensionLabelTextAnchor = (angle: number) => {
    if (isBarVisualization(self.getVif())) {
      return 'end';
    } else {
      if (angle > 0) {
        return 'start';
      } else if (angle < 0) {
        return 'end';
      } else {
        return 'middle';
      }
    }
  };

  this.isSummaryTableVisualization = () => {
    return isTableVisualization(self.getVif()) && _.get(this.getOptions(), 'isSummaryTable') === true;
  };

  this.isMeasure = () => {
    return _.get(this.getOptions(), 'isMeasure') === true;
  };

  this.showPanningNotice = () => {
    PanningNoticeContainer.show(self.$container);
    self.panningNoticeVisible = true;
    // TODO: is this a bug
    InfoContainer.showInfo(self);
  };

  this.hidePanningNotice = () => {
    PanningNoticeContainer.hide(self.$container);
    self.panningNoticeVisible = false;
    // TODO: is this a bug
    InfoContainer.hideInfo(self);
  };

  this.isMobile = isMobileOrTablet;

  const isEmptyDataToRender = (dataToRender: DataToRender, excludingValues: any[]) => {
    const dimensionIndex = dataToRender.columns.indexOf('dimension');
    const allSeriesMeasureValues = dataToRender.rows.map((row) => {
      return row.slice(dimensionIndex + 1);
    });

    return _.chain(allSeriesMeasureValues)
      .flatten()
      .without(...excludingValues)
      .isEmpty()
      .value();
  };

  this.isOnlyNullValues = (dataToRender: DataToRender) =>
    isEmptyDataToRender(dataToRender, [null, undefined, '']);

  this.isOnlyNullOrZeroValues = (dataToRender: DataToRender) =>
    isEmptyDataToRender(dataToRender, [null, undefined, '', 0]);

  this.isInRange = (value: number, minValue: number, maxValue: number) =>
    value >= minValue && value <= maxValue;

  this.getTypeVariantBySeriesIndex = (seriesIndex: number) => {
    const actualSeriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(seriesIndex);
    const typeComponents = _.get(self.getVif(), `series[${actualSeriesIndex}].type`, '').split('.');

    return typeComponents.length > 1 ? typeComponents[1] : DEFAULT_TYPE_VARIANTS[typeComponents[0]];
  };

  // vif series selectors
  this.getUnitOneBySeriesIndex = (seriesIndex: number) => {
    const actualSeriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(seriesIndex);
    const unitOne = _.get(self.getVif(), `series[${actualSeriesIndex}]unit.one`);

    if (_.isString(unitOne) && !_.isEmpty(unitOne)) {
      return unitOne;
    } else {
      return '';
    }
  };

  this.getUnitOtherBySeriesIndex = (seriesIndex: number) => {
    const actualSeriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(seriesIndex);
    const unitOther = _.get(self.getVif(), `series[${actualSeriesIndex}]unit.other`);

    if (_.isString(unitOther) && !_.isEmpty(unitOther)) {
      return unitOther;
    } else {
      return '';
    }
  };

  this.getHighlightColorBySeriesIndex = (seriesIndex: number) => {
    const actualSeriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(seriesIndex);
    const highlightColor = _.get(self.getVif(), `series[${actualSeriesIndex}].color.highlight`);

    return !_.isUndefined(highlightColor) ? highlightColor : DEFAULT_HIGHLIGHT_COLOR;
  };

  /**
   * Valid options: 'fit', 'pan', 'showZero'
   */
  this.getXAxisScalingModeBySeriesIndex = (seriesIndex: number) => {
    const actualSeriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(seriesIndex);
    const chartType = _.get(self.getVif(), `series[${actualSeriesIndex}].type`, '');
    const isTimeline = chartType.match(/^timeline/);
    const defaultXAxisScalingModeForChartType = isTimeline ? 'fit' : 'pan';

    return _.get(self.getVif(), 'configuration.xAxisScalingMode', defaultXAxisScalingModeForChartType);
  };

  this.getLineStyleBySeriesIndex = (seriesIndex: number): LineStyle => {
    const actualSeriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(seriesIndex);
    const defaults = {
      points: 'none',
      pattern: 'solid',
      horizontalAlignment: 'middle'
    };

    return _.merge(defaults, _.get(self.getVif(), ['series', actualSeriesIndex, 'lineStyle']));
  };

  this.generateYScale = (minValue: number, maxValue: number, height: number) => {
    let y;

    if (logScale(self.getVif())) {
      minValue = minValue > 0 ? minValue : 1;
      maxValue = maxValue > 0 ? maxValue : 1;
      y = d3.scale.log().clamp(true).domain([minValue, maxValue]).range([height, GLYPH_SPACE_HEIGHT]);
    } else {
      y = d3.scale.linear().domain([minValue, maxValue]).range([height, GLYPH_SPACE_HEIGHT]);
    }
    return y;
  };

  this.emitEvent = (name: string, payload: any) => {
    self.$element[0].dispatchEvent(new window.CustomEvent(name, { detail: payload, bubbles: true }));
  };

  this.isEmbedded = () => {
    return self.$container.closest('.socrata-visualization-embed').length > 0;
  };

  this.isInStory = () => self.$container && self.$container.closest('.user-story-container').length > 0;

  this.shouldDisplayFilterBar = () => _.get(self.getOptions(), 'displayFilterBar', false);
  this.filterBarIsEditable = () => _.get(self.getOptions(), 'filterBarIsEditable', false);
  this.filterBarDisabled = () => _.get(self.getOptions(), 'filterBarDisabled', false);
  this.filterBarDisabledMessage = () => _.get(self.getOptions(), 'filterBarDisabledMessage');

  this.getPositionsForRange = (rows: DataRow[], minValue: number, maxValue: number) => {
    const positions = getPositions(rows);
    return adjustPositionsToFitRange(positions, minValue, maxValue);
  };

  this.getStackedPositionsForRange = (rows: DataRow[], minValue: number, maxValue: number) => {
    const positions = self.getStackedPositions({
      isOneHundredPercentStacked: false,
      rows
    });
    return adjustPositionsToFitRange(positions, minValue, maxValue);
  };

  this.getStackedPositions = ({
    isOneHundredPercentStacked,
    rows
  }: GetStackedPositionsOptions): Position[][] => {
    return rows.map((row) => {
      const values = row.slice(1); // first index is the dimension name
      const sumOfAbsoluteValues = values.reduce<number>(
        (sum, value) => sum + Math.abs(typeof value === 'number' ? value : 0),
        0
      );

      let positiveOffset = 0;
      let negativeOffset = 0;

      return values.map((o) => {
        if (sumOfAbsoluteValues === 0) {
          return { start: 0, end: 0, percent: 0 };
        }

        const dataValue = typeof o === 'number' ? o : 0;
        const dataPercent = dataValue / sumOfAbsoluteValues;

        let position;
        const value = isOneHundredPercentStacked ? dataPercent : dataValue;

        if (value >= 0) {
          position = {
            end: positiveOffset + value,
            percent: dataPercent * 100,
            start: positiveOffset
          };
          positiveOffset += value;
        } else {
          position = {
            end: negativeOffset,
            percent: dataPercent * 100,
            start: negativeOffset + value
          };
          negativeOffset += value;
        }

        return position;
      });
    });
  };

  this.getMaxOneHundredPercentStackedValue = (positions: Position[][]) => {
    const sumOfPositivePercents = positions.map(
      // for each row of positions
      (row: Position[]) =>
        _.filter(row, (position: Position) => position.percent !== undefined && position.percent > 0) // filter for positions with positive percents
          .reduce((sum, position) => sum + position.percent!, 0)
    ); // sum the positive percents

    return d3.max(sumOfPositivePercents) / 100;
  };

  this.getMinOneHundredPercentStackedValue = (positions: Position[][]) => {
    const sumOfNegativePercents = positions.map(
      // for each row of positions
      (row: Position[]) =>
        _.filter(row, (position: Position) => position.percent !== undefined && position.percent < 0) // filter for positions with negative percents
          .reduce((sum: number, position: Position) => sum + position.percent!, 0)
    ); // sum the negative percents

    return d3.min(sumOfNegativePercents) / 100;
  };

  this.filterDataToRender = (
    dataToRender: DataToRender,
    predicate: (seriesItem: Series, index: number) => boolean
  ) => {
    const clonedDataToRender = _.cloneDeep(dataToRender);

    // Get indices of anything that is excluded by the predicate
    const series = _.get(self.getVif(), 'series');
    const indicesToExcise = series.reduce((indices: number[], seriesItem: Series, index: number) => {
      if (!predicate(seriesItem, index)) {
        indices.push(index + 1);
      }
      return indices;
    }, []);

    // Excise from columns
    for (let j = indicesToExcise.length - 1; j >= 0; j--) {
      clonedDataToRender.columns.splice(indicesToExcise[j], 1);
    }

    // Excise from seriesIndices
    for (let k = indicesToExcise.length - 1; k >= 0; k--) {
      clonedDataToRender.seriesIndices.splice(indicesToExcise[k], 1);
    }

    // Excise from rows
    clonedDataToRender.rows = clonedDataToRender.rows.map((row) => {
      for (let i = indicesToExcise.length - 1; i >= 0; i--) {
        row.splice(indicesToExcise[i], 1);
      }
      return row;
    });

    return clonedDataToRender;
  };

  this.getDataToRenderOfSeriesType = (dataToRender: DataToRender, seriesType: SeriesType) => {
    return this.filterDataToRender(dataToRender, (seriesItem: Series) => seriesItem.type === seriesType);
  };

  this.addSeriesIndices = (dataToRender: DataToRender) => {
    const grouped = isGrouping(self.getVif());
    dataToRender.seriesIndices = [null]; // first column is dimension

    for (let i = 0; i < dataToRender.columns.length - 1; i++) {
      const seriesIndex = grouped ? 0 : i;
      dataToRender.seriesIndices.push(seriesIndex);
    }
  };

  /**
   * Only apply precision if the aggregation function is average and there
   * is not a precision set on the column already.
   */
  this.setDefaultMeasureColumnPrecision = (dataToRender: Pick<DataToRender, 'columnFormats'>) => {
    const allSeries = _.get(self.getVif(), 'series');
    const columnFormats = _.cloneDeep(dataToRender.columnFormats);

    _.forEach(allSeries, (series) => {
      const aggregationFunction = _.get(series, 'dataSource.measure.aggregationFunction');
      if (aggregationFunction !== 'avg') {
        return;
      }

      const columnName = _.get(series, 'dataSource.measure.columnName');

      if (!_.isNil(columnName)) {
        const columnFormat: ColumnFormat = _.find(columnFormats, { fieldName: columnName }) as ColumnFormat;
        if (!_.has(columnFormat, 'format.precision')) {
          _.set(columnFormat!, 'format.precision', '2');
        }
      }
    });

    dataToRender.columnFormats = columnFormats;
  };

  this.getDimensionColumnFormattedValueText = ({
    dataToRender,
    value
  }: GetDimensionColumnFormattedValueTextOptions) => {
    // Since dimension is series invariant, we can use seriesIndex 0
    let columnName = _.get(self.getVif(), 'series[0].dataSource.dimension.columnName');
    const currentDrilldownColumnName = getCurrentDrilldownColumnName(this.getVif());
    if (shouldRenderDrillDown(this.getVif()) && currentDrilldownColumnName) {
      columnName = currentDrilldownColumnName;
    }

    return self.getColumnFormattedValueText({
      columnName,
      dataToRender,
      value
    });
  };

  this.getMeasureColumnFormattedValueText = ({
    dataToRender,
    measureIndex,
    value
  }: GetMeasureColumnFormattedValueTextOptions) => {
    const seriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(measureIndex);
    const columnName = _.get(self.getVif(), `series[${seriesIndex}].dataSource.measure.columnName`);
    return self.getColumnFormattedValueText({
      dataToRender,
      columnName,
      value
    });
  };

  interface GetMeasureColumnFormattedValueWithUnitsAndPercentTextOptions {
    dataToRender: DataToRender;
    measureIndex: number;
    percent?: number;
    value: number;
  }
  const getMeasureColumnFormattedValueWithUnitsAndPercentText = ({
    dataToRender,
    measureIndex,
    percent,
    value
  }: GetMeasureColumnFormattedValueWithUnitsAndPercentTextOptions) => {
    let valueText = self.getMeasureColumnFormattedValueText({
      dataToRender,
      measureIndex,
      value
    });

    const seriesIndex = self.defaultToSeriesIndexZeroIfGroupingIsEnabled(measureIndex);
    if (value === 1) {
      const unitOne = self.getUnitOneBySeriesIndex(seriesIndex);
      if (!_.isEmpty(unitOne)) {
        valueText += ` ${unitOne}`;
      }
    } else {
      const unitOther = self.getUnitOtherBySeriesIndex(seriesIndex);
      if (!_.isEmpty(unitOther)) {
        valueText += ` ${unitOther}`;
      }
    }

    if (percent !== undefined && !isNaN(percent)) {
      const percentSymbol = I18n.t('shared.visualizations.charts.common.percent_symbol');
      valueText += ` (${Math.round(percent)}${percentSymbol})`;
    }

    return valueText;
  };

  this.getColumnFormattedValueText = ({
    columnName,
    dataToRender,
    value
  }: GetColumnFormattedValueTextOptions) => {
    if (shouldDisplayNoValue({ columnName, dataToRender, value })) {
      return noValueLabel;
    } else if (shouldDisplayFalse(columnName, dataToRender, value)) {
      return falseLabel;
    } else if (value === otherLabel) {
      return otherLabel;
    } else {
      // Axis labels display the column formatted value as is (no force humane)
      return formatValuePlainText(value, columnName, dataToRender);
    }
  };

  this.getPercentFormattedValueText = ({ percent }: GetPercentFormattedValueTextOptions) => {
    return isNaN(percent)
      ? ''
      : `${Math.round(percent)}${I18n.t('shared.visualizations.charts.common.percent_symbol')}`;
  };

  this.isPercentLabelInside = ({
    dimensionIndex,
    measureIndex,
    percent,
    positions,
    scale
  }: IsPercentLabelInsideOptions) => {
    const position = self.getPosition(positions, measureIndex, dimensionIndex);
    const shapeLength = getShapeLength(position, scale);
    const text = self.getPercentFormattedValueText({ percent });
    const { width: textLength } = calculateTextSize(FONT_STACK, VALUE_LABEL_FONT_SIZE, text);

    return shapeLength >= textLength + VALUE_LABEL_MARGIN * 2;
  };

  // TODO: vif selector
  this.shouldShowValueLabelsAsPercent = () => {
    return (
      getShowValueLabelsAsPercent(self.getVif()) && !isGroupingOrHasMultipleNonFlyoutSeries(self.getVif())
    );
  };

  this.isValueLabelInside = ({
    d,
    dataToRender,
    dimensionIndex,
    measureIndex,
    positions,
    scale
  }: IsValueLabelInsideOptions) => {
    const position = self.getPosition(positions, measureIndex, dimensionIndex);
    const shapeLength = getShapeLength(position, scale);
    const text = self.getMeasureColumnFormattedValueText({
      dataToRender,
      measureIndex,
      value: d
    });
    const { width: textLength } = calculateTextSize(FONT_STACK, VALUE_LABEL_FONT_SIZE, text);

    return shapeLength >= textLength + VALUE_LABEL_MARGIN * 2;
  };

  this.getValueLabelY = ({
    dimensionScale,
    groupingScale,
    measureIndex,
    isStacked
  }: GetValueLabelYOptions) => {
    return Math.floor(
      isStacked
        ? (dimensionScale.rangeBand() - 1) / 2 + VALUE_LABEL_FONT_SIZE / 2 + MEASURE_VALUE_TEXT_Y_SHIFT
        : (groupingScale.rangeBand() - 1) / 2 +
            VALUE_LABEL_FONT_SIZE / 2 +
            MEASURE_VALUE_TEXT_Y_SHIFT +
            groupingScale(measureIndex)
    );
  };

  this.getGroupFlyoutContent = ({
    dimensionIndex,
    dimensionValue,
    flyoutDataToRender,
    hideNullsInFlyout,
    measures,
    nonFlyoutDataToRender,
    title
  }: GetGroupFlyoutContentOptions) => {
    // Title
    const titleText = !_.isNil(title)
      ? title
      : this.getDimensionColumnFormattedValueText({
          dataToRender: nonFlyoutDataToRender,
          value: dimensionValue
        });
    const $titleDiv = $('<div>', { class: 'socrata-flyout-title' });
    if (!_.isEmpty(titleText)) {
      $titleDiv.text(titleText);
    }

    // Normal measures table (non-flyout measures)
    const $measuresTable = getMeasuresTable({
      dimensionIndex,
      hideNullsInFlyout,
      nonFlyoutDataToRender,
      measures
    });

    // Container div
    const $content = $('<div>').append([$titleDiv, $measuresTable as JQuery]);

    // Flyout measures table
    if (!isGrouping(self.getVif())) {
      const $flyoutMeasuresTable = getFlyoutMeasuresTable({
        dimensionIndex,
        dimensionValue,
        flyoutDataToRender
      });

      if (!_.isNil($flyoutMeasuresTable)) {
        $content.append($flyoutMeasuresTable);
      }
    }

    return $content;
  };

  this.getAnnotationFlyoutContent = ({ date, description }: GetAnnotationFlyoutContentOptions) => {
    const $dateDiv = $('<div>', { class: 'socrata-flyout-date' }).text(date);
    const $descriptionDiv = $('<div>', { class: 'socrata-flyout-description' }).text(description);

    return $('<div>').append([$dateDiv, $descriptionDiv]);
  };

  this.getFlyoutContent = ({
    dimensionIndex,
    dimensionValue,
    flyoutDataToRender,
    measureIndex,
    measures,
    nonFlyoutDataToRender,
    percent,
    value
  }: GetFlyoutContentOptions) => {
    // Column measure
    const measure = measures[measureIndex];
    const seriesIndex = this.defaultToSeriesIndexZeroIfGroupingIsEnabled(measureIndex);
    // Title
    const titleText = this.getDimensionColumnFormattedValueText({
      dataToRender: nonFlyoutDataToRender,
      value: dimensionValue
    });
    const $titleDiv = $('<div>', { class: 'socrata-flyout-title' }).text(titleText);

    // Normal measures table (non-flyout measures)
    const $measuresTable = $('<table>', { class: 'socrata-flyout-table' });

    // Color cell
    const $colorCell = $('<td>', { class: 'socrata-flyout-cell' });

    if (!_.isEmpty(measure.labelHtml)) {
      $colorCell.append(
        $('<span>').css('background-color', measure.getColor(dimensionIndex, dimensionValue))
      );
    }

    // Value cell
    const $valueCell = $('<td>', { class: 'socrata-flyout-cell' });
    const valueText = getMeasureColumnFormattedValueWithUnitsAndPercentText({
      dataToRender: nonFlyoutDataToRender,
      measureIndex: seriesIndex,
      percent,
      value
    });

    $valueCell.text(valueText);

    // Label cell
    const $labelCell = $('<td>', { class: 'socrata-flyout-cell' }).html(measure.labelHtml!);

    // Row
    const $row = $('<tr>', { class: 'socrata-flyout-row' });

    $row.append([$colorCell, $labelCell, $valueCell]);

    $measuresTable.append($row);

    const $content = $('<div>').append([$titleDiv, $measuresTable]);

    if (!isGrouping(self.getVif())) {
      // Flyout measures table
      const $flyoutMeasuresTable = getFlyoutMeasuresTable({
        dimensionIndex,
        dimensionValue,
        flyoutDataToRender
      });

      if (!_.isNil($flyoutMeasuresTable)) {
        $content.append($flyoutMeasuresTable);
      }
    }

    if (shouldRenderDrillDown(this.getVif())) {
      const $drilldownFlyoutElement = $('<div>', { class: 'drill-down-flyout' }).text(
        I18n.t('shared.visualizations.panes.data.fields.drill_down.flyout.title')
      );
      $content.append($drilldownFlyoutElement);
    }

    return $content;
  };

  interface GetMeasuresTableOptions {
    dimensionIndex: number;
    hideNullsInFlyout: boolean;
    measures: VisualizationMeasure[];
    nonFlyoutDataToRender: DataToRender;
  }
  const getMeasuresTable = ({
    dimensionIndex,
    hideNullsInFlyout,
    measures,
    nonFlyoutDataToRender
  }: GetMeasuresTableOptions) => {
    const row = nonFlyoutDataToRender.rows[dimensionIndex] as number[];

    if (row.length <= 1) {
      return null;
    }

    const $table = $('<table></table>');
    $table.attr('class', 'socrata-flyout-table');
    const grouped = isGrouping(self.getVif());

    for (let i = 1; i < row.length; i++) {
      const value = row[i];

      if (value === null && (self.shouldDropNullFlyouts() || hideNullsInFlyout)) {
        continue;
      }

      const seriesIndex = grouped ? 0 : i - 1;
      const measure = measures[i - 1];

      // Color cell (or spacer cell)
      const $colorCell = $('<td>', { class: 'socrata-flyout-cell' });
      const $colorSpan = $('<span>');

      if (!_.isEmpty(measure.labelHtml)) {
        $colorSpan.css('background-color', measure.getColor());
      }

      $colorCell.append($colorSpan);

      // Label cell
      const $labelCell = $('<td>', { class: 'socrata-flyout-cell' }).html(measure.labelHtml!);

      // Value cell
      const valueText = getMeasureColumnFormattedValueWithUnitsAndPercentText({
        dataToRender: nonFlyoutDataToRender,
        measureIndex: seriesIndex,
        value
      });
      const $valueCell = $('<td>', { class: 'socrata-flyout-cell' }).text(valueText);

      // Row
      const $row = $('<tr>', { class: 'socrata-flyout-row' });
      $row.append([$colorCell, $labelCell, $valueCell]);
      $table.append($row);
    }

    return $table;
  };

  interface GetMeasureColumnNameOptions {
    columnFormats: ColumnFormat[] | { [fieldName: string]: ColumnFormat };
    seriesIndex: number;
  }
  const getMeasureColumnName = ({ columnFormats, seriesIndex }: GetMeasureColumnNameOptions) => {
    const measureColumnName = this.getVif().series[seriesIndex].dataSource.measure?.columnName ?? '';

    if (!_.isNil(measureColumnName)) {
      const format = columnFormats[measureColumnName];

      if (!_.isNil(format)) {
        return DataTypeFormatter.renderFormattedTextHTML(format.name);
      }
    }

    return null;
  };

  interface GetSeriesIndexByFlyoutIndexOptions {
    flyoutIndex: number;
  }
  const getSeriesIndexByFlyoutIndex = ({ flyoutIndex }: GetSeriesIndexByFlyoutIndexOptions) => {
    return isGrouping(self.getVif())
      ? // When grouping, there is only one grouped series followed by the flyout series
        flyoutIndex + 1
      : // Otherwise the flyout series follows all the non-flyout series
        flyoutIndex + getNonFlyoutSeries(self.getVif()).length;
  };

  interface GetFlyoutMeasuresTableOptions {
    dimensionIndex: number;
    dimensionValue: number;
    flyoutDataToRender: DataToRender;
  }
  const getFlyoutMeasuresTable = ({
    dimensionIndex,
    dimensionValue,
    flyoutDataToRender
  }: GetFlyoutMeasuresTableOptions) => {
    if (_.isNil(flyoutDataToRender) || !_.isArray(flyoutDataToRender.rows)) {
      return;
    }

    const dimensionValueIndex = 0;
    let row: string[] | number[] = [];

    // The flyoutDataToRender may not be in the same order as the
    // nonFlyoutDataToRender, so we must find the correct data row by
    // the dimensionValue.
    if (!_.isNil(dimensionValue)) {
      row = _.find(
        flyoutDataToRender.rows,
        (_row) => _row[dimensionValueIndex] === dimensionValue
      ) as string[];
    } else if (!_.isNil(dimensionIndex)) {
      // Scatter chart uses the dimensionIndex directly. There is no
      // dimensionValue in this case.
      row = flyoutDataToRender.rows[dimensionIndex] as string[];
    }

    if (_.isNil(row) || row.length <= 1) {
      return null;
    }

    const $table = $('<table>');
    $table.attr('class', 'socrata-flyout-flyout-measures-table');

    // The 0th entry in the row is the dimension value, then follows the series values. So begin at 1.
    for (let i = 1; i < row.length; i++) {
      // Series index
      const seriesIndex = getSeriesIndexByFlyoutIndex({
        flyoutIndex: i - 1
      });

      // Label cell
      const labelHtml =
        getMeasureColumnName({
          seriesIndex,
          columnFormats: flyoutDataToRender.columnFormats
        }) || I18n.t('shared.visualizations.panes.data.fields.measure.no_value');

      const $labelCell = $('<td>', { class: 'socrata-flyout-cell' }).html(labelHtml);

      // Value cell
      let value = row[i];
      const columnName = _.get(self.getVif(), `series[${seriesIndex}].dataSource.measure.columnName`);
      const formatInfo = _.get(flyoutDataToRender, `columnFormats.${columnName}`, {});
      // Sometimes we are reading in values that are numbers formatted as text,
      // if the format renderType is text, ensure the value is encoded as text.
      if (formatInfo && formatInfo.renderTypeName === 'text') {
        value = String(value);
      }

      const valueText = self.getMeasureColumnFormattedValueText({
        dataToRender: flyoutDataToRender,
        measureIndex: seriesIndex,
        value
      });
      const $valueCell = $('<td>', { class: 'socrata-flyout-cell' }).text(valueText);

      // Row
      const $row = $('<tr>', { class: 'socrata-flyout-row' });
      $row.append([$labelCell, $valueCell]);
      $table.append($row);
    }

    return $table;
  };

  this.isAboveMeasureAxisMaxValue = (value: number) => {
    const maxValue = getMeasureAxisMaxValue(self.getVif());
    return maxValue !== null && value >= maxValue;
  };

  this.isBelowMeasureAxisMinValue = (value: number) => {
    const minValue = getMeasureAxisMinValue(self.getVif());
    return minValue !== null && value <= minValue;
  };

  const getShapeLength = (position: Position, scale: (x: number) => number) =>
    Math.abs(scale(position.end) - scale(position.start));
  this.getPosition = (positions: Position[][], measureIndex: number, dimensionIndex: number) =>
    positions[dimensionIndex][measureIndex];

  this.showReferenceLineFlyout = ({
    dataToRender,
    element,
    flyoutOffset,
    referenceLines
  }: ShowReferenceLineFlyoutOptions) => {
    const index = parseInt(element.getAttribute('data-reference-line-index')!, 10);
    const referenceLine = referenceLines[index];

    const $table = $('<table>', { class: 'socrata-flyout-table' });
    const $titleRow = $('<tr>', { class: 'socrata-flyout-title' }).append(
      $('<td>', { colspan: 2 }).text(referenceLine.label)
    );

    const columnName = _.get(this.getVif(), 'series[0].dataSource.measure.columnName', '');
    let value = formatValueHTML(referenceLine.value, columnName, dataToRender, true);

    if (referenceLine.value === 1) {
      const oneUnit = _.get(referenceLine, 'unit.one');
      if (!_.isEmpty(oneUnit)) {
        value += ` ${oneUnit}`;
      }
    } else {
      const otherUnit = _.get(referenceLine, 'unit.other');
      if (!_.isEmpty(otherUnit)) {
        value += ` ${otherUnit}`;
      }
    }

    const $valueRow = $('<tr>', { class: 'socrata-flyout-row' });

    if (_.isEmpty(referenceLine.label)) {
      $valueRow.append($('<td>', { class: 'socrata-flyout-cell' }).text(value));
    } else {
      $valueRow.append($('<td>')).append($('<td>', { class: 'socrata-flyout-cell' }).text(value));

      $table.append($titleRow);
    }

    $table.append($valueRow);

    const payload = {
      element,
      content: $table,
      rightSideHint: false,
      belowTarget: false,
      dark: true,
      flyoutOffset
    };

    this.emitEvent('SOCRATA_VISUALIZATION_FLYOUT', payload);
  };

  this.getRowValueExtent = ({ errorBarValues, referenceLines, rowValues }: GetRowValueExtentOptions) => {
    const rowValuesExtent = d3.extent(rowValues || []);
    const referenceLineValuesExtent = d3.extent(referenceLines || [], (o) => o.value);
    const errorBarValuesExtent = d3.extent(errorBarValues || []);

    const max = d3.max([rowValuesExtent[1], referenceLineValuesExtent[1], errorBarValuesExtent[1]]);
    const min = d3.min([rowValuesExtent[0], referenceLineValuesExtent[0], errorBarValuesExtent[0]]);

    return { max, min };
  };

  this.getRowValueSummedExtent = ({
    columnOrBarRows,
    dimensionIndex,
    lineRowValues,
    referenceLines
  }: GetRowValueSummedExtentOptions) => {
    const summedRowValues = _.map(columnOrBarRows, (row) => {
      let min = 0;
      let max = 0;
      for (let i = dimensionIndex + 1; i < row.length; i++) {
        const value = row[i];
        const rowValue = typeof value === 'number' ? value : 0;
        max += Math.max(rowValue, 0);
        min += Math.min(rowValue, 0);
      }

      return { max, min };
    });

    const maxSummedRowValue = d3.max(summedRowValues, (o) => o.max);
    const minSummedRowValue = d3.min(summedRowValues, (o) => o.min);

    const referenceLineValuesExtent = d3.extent(referenceLines || [], (o) => o.value);
    const lineRowValuesExtent = d3.extent(lineRowValues || []);

    const max = d3.max([maxSummedRowValue, referenceLineValuesExtent[1], lineRowValuesExtent[1]]);
    const min = d3.min([minSummedRowValue, referenceLineValuesExtent[0], lineRowValuesExtent[0]]);

    return { max, min };
  };

  // If dimension grouping is enabled, there will only be one actual series in
  // the vif although there will appear to be multiple series in the data table
  // resulting from it. Many methods on this class return configuration
  // properties by series index. Accordingly, if dimension grouping is enabled
  // we want to read these configuration properties off of the only actual
  // series (at index zero) as opposed to one of the 'virtual' series that
  // appear to exist based on the data table.
  this.defaultToSeriesIndexZeroIfGroupingIsEnabled = (seriesIndex: number) => {
    return isGrouping(self.getVif()) ? 0 : seriesIndex;
  };

  // If we're using "Exclude missing values" on the measure and there is a grouping column,
  // then we need to hide flyouts on those null groups.
  // Yes, this is a bit of an opinionated thing, but we agreed this made the most sense.
  // We also decided NOT to scrunch the bars together to fill the empty space.
  // This is only active on vizes which can have a grouping column, which is currently
  // barChart, columnChart, and timelineChart
  this.shouldDropNullFlyouts = () => {
    const v = self.getVif();
    if (!isGrouping(v)) return false;
    const dataSource = v.series[0].dataSource;
    if (!dataSource || !dataSource.filters || !dataSource.measure || !dataSource.measure.columnName) {
      return false;
    }
    const measureColumnName = dataSource.measure.columnName;
    const measureFilter = _.find(dataSource.filters, function (filter) {
      return filter.columns[0].fieldName === measureColumnName;
    });
    return measureFilter ? measureFilter.function === 'excludeNull' : false;
  };

  // This will get the value of `blockId.layout.i` if it was set in the current rendering mode.
  // Currently, this only works in Storyteller, Flexible layout, view mode.
  this.getStorytellerItemId = () => {
    return self.$element.closest('.react-grid-item').attr('data-item-id');
  };

  /**
   * Private methods
   */

  // We add `aria-hidden` to the .socrata-visualization-chart-container element by default because
  // we don't have a good story for making charts accessible. But we do remove the aria-hidden
  // attr for Tables and UnifiedMap currently. For UnifiedMap, we add it back on destroy.
  function renderTemplate() {
    const $chartContainer = $('<div>', {
      class: 'socrata-visualization-chart-container',
      'aria-hidden': !isTableVisualization(self.getVif())
    });
    const $visualizationContainer = $('<div>', { class: 'socrata-visualization-container' });

    if (!isTableVisualization(self.getVif())) {
      const _$summaryTableContainer = $('<div>', { class: 'socrata-visualization-summary-table-container' });

      const $chartTabPanel = ViewTabsContainer.tabPanelTemplate(
        false,
        $chartContainer,
        'visualization',
        self.visualizationId,
        isMapVisualization(self.getVif()) ? 'map' : 'chart'
      );

      const $summaryTableTabPanel = ViewTabsContainer.tabPanelTemplate(
        true,
        _$summaryTableContainer,
        'summary-table',
        self.visualizationId,
        'table'
      );

      $visualizationContainer.append([$chartTabPanel, $summaryTableTabPanel]);
    } else {
      $visualizationContainer.append($chartContainer);
    }

    const metadataContainer = self.isSummaryTableVisualization() ? null : MetadataContainer.template();

    const infoContainer = self.isSummaryTableVisualization()
      ? null
      : InfoContainer.template(self, self.visualizationId);

    const panningNoticeContainer = self.isSummaryTableVisualization()
      ? null
      : PanningNoticeContainer.template();

    const actionsToolbarContainer = self.isSummaryTableVisualization()
      ? null
      : ActionsToolbarContainer.template().append([
          FilterBarContainer.template(),
          ExpandCollapseContainer.template(),
          infoContainer as JQuery
        ]);

    // We do not want to append the footer for table visualizations
    const $footerContainer = isTableVisualization(self.getVif())
      ? null
      : $('<div>', { class: 'visualization-forge-footer' }).append([
          ViewTabsContainer.template(self) as JQuery,
          $('<div>', { class: 'socrata-visualization-chart-info-container' }).append([
            panningNoticeContainer as JQuery,
            LegendBarContainer.template()
          ])
        ]);

    if (newVizCardLayoutEnabled(self.getVif())) {
      self.$element.append(
        $('<div>', { id: self.visualizationId, class: 'socrata-visualization' }).append([
          $('<div>', { class: 'visualization-forge-header' }).append([
            metadataContainer as JQuery,
            actionsToolbarContainer as JQuery
          ]),
          $('<div>', { class: 'visualization-content' }).append([
            $('<div>', { class: 'visualization-content-header' }).append([DrilldownContainer.template()]),
            $visualizationContainer as JQuery,
            LegendPaneContainer.template()
          ]),
          $footerContainer as JQuery,
          $('<div>', { class: 'socrata-visualization-error-container error light' }).append([
            $('<span>', { class: 'socrata-visualization-error-message text' })
          ]),
          $('<div>', { class: 'socrata-visualization-busy-indicator-container' }).append([
            $('<span>', { class: 'socrata-visualization-busy-indicator' })
          ])
        ])
      );
    } else {
      self.$element.append(
        $('<div>', { id: self.visualizationId, class: 'socrata-visualization' }).append([
          metadataContainer as JQuery,
          FilterBarContainer.template(),
          ExpandCollapseContainer.template(),
          DrilldownContainer.template(),
          ViewTabsContainer.template(self) as JQuery,
          $visualizationContainer as JQuery,
          LegendPaneContainer.template(),
          $('<div>', { class: 'socrata-visualization-chart-info-container' }).append([
            panningNoticeContainer as JQuery,
            LegendBarContainer.template()
          ]),
          infoContainer as JQuery,
          $('<div>', { class: 'socrata-visualization-error-container error light' }).append([
            $('<span>', { class: 'socrata-visualization-error-message text' })
          ]),
          $('<div>', { class: 'socrata-visualization-busy-indicator-container' }).append([
            $('<span>', { class: 'socrata-visualization-busy-indicator' })
          ])
        ])
      );
    }
    if (!printTableEnabled) {
      // whoops I just missed adding brackets here
      InfoContainer.renderReact(self, { vizUid: self.visualizationId });
    }

    ViewTabsContainer.renderReact(
      self.$element,
      isMapVisualization(self.getVif()),
      () => self.updateShouldShowTable(true),
      () => self.updateShouldShowTable(false),
      currentShouldShowTable,
      self.visualizationId,
      newVizCardLayoutEnabled(self.getVif())
    );

    // We can remove this check when we switch to using Tyler Forge Styling for all customers
    if (newVizCardLayoutEnabled(self.getVif())) {
      self.$element.find('.socrata-visualization').addClass('forge-card-layout');
    }
  }

  function attachEvents() {
    // Destroy on (only the first) 'SOCRATA_VISUALIZATION_DESTROY' event.
    self.$element.one('SOCRATA_VISUALIZATION_DESTROY', () => {
      self.$element.find('.socrata-visualization').remove();
      MetadataContainer.detachEvents(self);
    });

    MetadataContainer.attachEvents(self);
  }

  function getTextContent(
    context: Element | null,
    event: JQuery.TriggeredEvent
  ): [Element, string | null | undefined] {
    const element = context instanceof Element ? context : (event.originalEvent?.target as HTMLElement);

    const content = element.querySelector('text')
      ? element.querySelector('text')?.textContent
      : element.getAttribute('data-full-text');

    return [element, content];
  }

  this.showFlyout = (event: JQuery.TriggeredEvent) => {
    // This function is always called from a context where `this` will be the Jquery el
    const [element, content] = getTextContent(this as unknown as Element, event);

    if (content) {
      const customEvent = new window.CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
        detail: {
          element,
          content: $('<div>', { class: 'socrata-flyout-title' }).text(content),
          rightSideHint: false,
          belowTarget: false,
          dark: true
        },
        bubbles: true
      });

      self.$element[0].dispatchEvent(customEvent);
    }
  };

  this.hideFlyout = () => {
    self.$element[0].dispatchEvent(
      new window.CustomEvent('SOCRATA_VISUALIZATION_FLYOUT', {
        detail: null,
        bubbles: true
      })
    );
  };

  function getPositions(groupedDataToRender: DataRow[]): Position[][] {
    return groupedDataToRender.map((row) => {
      const values = row.slice(1); // first index is the dimension name

      return values.map((o) => {
        const value = typeof o === 'number' ? o : 0;
        return value >= 0 ? { start: 0, end: value } : { start: value, end: 0 };
      });
    });
  }

  function adjustPositionsToFitRange(positions: Position[][], minValue: number, maxValue: number) {
    positions.forEach((group: Position[]) => {
      group.forEach((position: Position) => {
        position.start = _.clamp(position.start, minValue, maxValue);
        position.end = _.clamp(position.end, minValue, maxValue);
      });
    });

    return positions;
  }

  function isCheckBoxColumn(
    dataToRender: Pick<DataToRender, 'columnFormats' | 'columns'>,
    columnName: string
  ) {
    const columnFormat = _.find(dataToRender.columnFormats, ['fieldName', columnName]);
    return _.get(columnFormat, 'renderTypeName') === CHECKBOX_COLUMN_TYPE;
  }

  function valueIsEmpty(value: number | string | null) {
    return _.isNil(value) || value === '';
  }

  interface ShouldDisplayNoValueOptions {
    columnName: string;
    dataToRender: Pick<DataToRender, 'columnFormats' | 'columns'>;
    value: number | string | null;
  }
  function shouldDisplayNoValue({ columnName, dataToRender, value }: ShouldDisplayNoValueOptions) {
    const isNil = _.isNil(value);
    const isCheckBox = isCheckBoxColumn(dataToRender, columnName);
    return (isNil && !isCheckBox) || (isNil && isCheckBox && !getShowNullsAsFalse(self.getVif()));
  }

  function shouldDisplayFalse(
    columnName: string,
    dataToRender: Pick<DataToRender, 'columnFormats' | 'columns'>,
    value: number | string | null
  ) {
    const isNil = valueIsEmpty(value);
    const isCheckBox = isCheckBoxColumn(dataToRender, columnName);
    return isCheckBox && isNil && getShowNullsAsFalse(self.getVif());
  }

  /**
   * Initialization
   */

  assertInstanceOf($element, $);

  this.$element = $element;
  // This is used to set unique ids needed for accessibility (there
  // can be multiple visualizations on a page in storyteller).
  this.visualizationId = _.uniqueId();

  renderTemplate();
  attachEvents();

  this.$container = self.$element.find('.socrata-visualization');

  this.updateVif(vif);

  this.updateOptions(options);
}

// This casting is necessary because BaseVisualization is a function that we treat as a class, and
// Typescript doesn't like using `new` with a function. See https://stackoverflow.com/a/43624326 for more.
export default BaseVisualization as any as {
  new (element: JQuery, originalVif: Vif, options?: VisualizationOptions): BaseVizType;
};
